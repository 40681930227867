import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Button,
  Box,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Switch,
} from "@mui/material";
import useSWR, { useSWRConfig } from "swr";
import { PATH_NAME } from "../../../configs/pathName";
import {
  activateAssetAPI,
  deleteAssetAPI,
  getAssetAPI,
} from "../../../api/AssetAPI";
import DataTable from "../../common/DataTable/DataTable";
import { IAssetItems, IAssets } from "../../../models/IAssets";
import { AWS_URL } from "../../../configs/constants";

const BannerListPage: React.FC = () => {
  const { mutate } = useSWRConfig();

  const navigate = useNavigate();

  const {
    data: banners,
    error,
    isLoading,
  } = useSWR(PATH_NAME.API_ADMIN_ASSETS, getAssetAPI);

  const [filteredBanner, setFilteredBanner] = useState<IAssets[]>([]);
  const [open, setOpen] = useState(false);
  const [selectedBannerId, setSelectedBannerId] = useState<number | null>(null);

  const columns = [
    { field: "id", headerName: "ID", flex: 0 },
    {
      field: "items",
      headerName: "Images",
      flex: 1,
      renderCell: (params: any) => (
        <Box sx={{ display: "flex", flexDirection: "row", gap: 1 }}>
          {Array.isArray(params.row.items) &&
            params.row.items.map((item: IAssetItems, index: number) => (
              <img
                key={index}
                src={`${AWS_URL}/${item.asset}`}
                alt={`Banner ${params.row.id} - ${index + 1}`}
                style={{
                  width: 50,
                  height: 50,
                  objectFit: "cover",
                  borderRadius: 4,
                }}
              />
            ))}
        </Box>
      ),
    },
    { field: "placement", headerName: "Placement", flex: 1 },
    {
      field: "active",
      headerName: "Active",
      flex: 0,
      renderCell: (params: any) => (
        <Switch
          checked={params.row.is_active}
          onChange={() => handleToggleActive(params.row.id)}
        />
      ),
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 0,
      renderCell: (params: any) => (
        <div>
          <IconButton
            aria-label="edit"
            size="small"
            color="primary"
            onClick={() => handleEdit(params.row.id)}
          >
            <EditIcon fontSize="small" />
          </IconButton>
          <IconButton
            aria-label="delete"
            size="small"
            color="primary"
            onClick={() => handleDeleteClick(params.row.id)}
          >
            <DeleteIcon fontSize="small" />
          </IconButton>
        </div>
      ),
    },
  ];

  const handleEdit = (id: number) => {
    setSelectedBannerId(id);
    navigate(`edit/${id}`);
  };

  const handleCreate = () => {
    navigate("add");
  };

  const handleDeleteClick = (id: number) => {
    setSelectedBannerId(id);
    setOpen(true);
  };

  const handleDeleteConfirm = async () => {
    if (selectedBannerId !== null) {
      try {
        await deleteAssetAPI(selectedBannerId);
        await mutate(PATH_NAME.API_ADMIN_ASSETS);
      } catch (error) {
        console.error("Failed to delete banner:", error);
      }
    }
    setOpen(false);
    setSelectedBannerId(null);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedBannerId(null);
  };

  const handleToggleActive = async (id: number) => {
    await activateAssetAPI(id);
    await mutate(PATH_NAME.API_ADMIN_ASSETS);
  };

  useEffect(() => {
    if (banners) {
      const filetered = banners
        .filter((banner: IAssets) => banner.placement === "banner")
        .sort((a: IAssets, b: IAssets) => (a.id ?? 0) - (b.id ?? 0));
      setFilteredBanner(filetered);
    }
  }, [banners]);

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error loading banners</div>;

  return (
    <div>
      <Box sx={{ display: "flex", justifyContent: "flex-end", mb: 2, gap: 1 }}>
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={handleCreate}
        >
          Add
        </Button>
      </Box>
      <DataTable
        rows={filteredBanner || []}
        columns={columns}
        getRowId={(row) => row.id}
        loading={isLoading}
      />
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{"Confirm Deletion"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this banner?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeleteConfirm} color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default BannerListPage;
