import React, { useState } from 'react';
import { Box, Container, CssBaseline, Typography, TextField, Button, IconButton, InputAdornment } from "@mui/material";
import { Link } from "react-router-dom";
import { useAppDispatch } from "../../../redux/features/redux-hooks";
import NommiLogo from '../../../assets/images/nommi_logo.png';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';
import { login } from "../../../redux/features/Auth/AuthThunk";
import { toast } from '../../common/SnackBar/snackBar';
import { VisibilityOff, Visibility } from '@mui/icons-material';

const Login: React.FC = () => {
    const dispatch = useAppDispatch();
    const [phone, setPhone] = useState("");
    const [password, setPassword] = useState("");
    const [countryCode, setCountryCode] = useState('');
    const [showPassword, setShowPassword] = useState(false);

    const handlePhoneChange = (value: string, data: any) => {
        const phoneNumber = value.slice(data.dialCode.length); 
        setPhone(phoneNumber);
        setCountryCode(`+${data.dialCode}`);
    };

    const handleLogin = async () => {
        const phoneNumber = phone.replace(/[^0-9]/g, "");

        if (phone && password) {
            try {
                await dispatch(
                    login({
                        country_code: countryCode,
                        mobile: phoneNumber,
                        password,
                    })
                ).unwrap();
                toast.success("Login successfully!");
            } catch (error) {
              toast.error("Phone Number & Password Wrong!");
            }
        } else {
          toast.error("Please enter Phone Number & Password.");
        }
    };

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100vh',
                }}
            >
                <Container maxWidth="xs">
                    <CssBaseline />
                    <Box
                        sx={{ display: 'flex', justifyContent: 'right', mb: 2 }}>
                        <img
                            alt="Company Logo"
                            src={NommiLogo}
                            style={{ width: 100 }}
                        />
                    </Box>
                    <Typography variant="h4" component="h1" gutterBottom align="left" color="#ff5e2c" fontWeight="500">
                        Hi! <br />
                    </Typography>
                    <Typography variant="h4" component="h1" gutterBottom align="left" fontWeight="500">
                        Welcome
                    </Typography>
                    <Typography variant="body1" gutterBottom align="left">
                        Please enter your details
                    </Typography>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                        }}
                    >
                        <Box sx={{ mt: 2, width: '100%' }}>
                            <PhoneInput
                                country={'sg'}
                                value={countryCode + phone}
                                onChange={handlePhoneChange}
                                inputProps={{
                                    name: 'phone',
                                    required: true,
                                    autoFocus: true,
                                }}
                                containerStyle={{ width: '100%' }}
                                inputStyle={{ width: '100%' }}
                            />

                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label="Password"
                                type={showPassword ? "text" : "password"}
                                id="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                edge="end"
                                            >
                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    mt: 2,
                                }}
                            >
                                <Typography variant="body2">
                                    <Link to={'/forget-password'}>
                                        Forgot password?
                                    </Link>
                                </Typography>
                            </Box>


                            <Button
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3, mb: 2, backgroundColor: '#ff5e2c', '&:hover': { backgroundColor: '#ff5e2c' } }}
                                onClick={handleLogin}
                            >
                                Login
                            </Button>
                            <Typography variant="body2" align="center">
                                Don’t have an account yet?{' '}
                                <Link to={'/Register'}>
                                    Sign up
                                </Link>
                            </Typography>
                        </Box>
                    </Box>
                </Container>
            </Box>
        </>
    );
};  

export default Login;