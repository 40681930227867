import axiosInstance from '../api/axiosInstance';
import { PATH_NAME } from '../configs/pathName';
import { IUserCoupon } from '../models/IUserCoupon';
import { handleError } from '../helpers/Fetchers/fetchers';


export const getUserCouponByIdAPI = async (id: string): Promise<IUserCoupon> => {
  try {
    const response = await axiosInstance.get(PATH_NAME.API_ADMIN_USER_COUPONS_BY_ID(id), {
    });
    if (response.data.success) {
      return response.data.result;
    } else {
      throw new Error('Failed to fetch voucher');
    }
  } catch (error) {
    handleError(error,`Error fetching voucher with code ${id}:`);
    throw error;
  }
};

export const createUserCouponAPI = async (userCouponData: IUserCoupon): Promise<IUserCoupon> => {
    try {
      const response = await axiosInstance.post(PATH_NAME.API_ADMIN_USER_COUPONS, userCouponData);
      return response.data;
    } catch (error) {
      handleError(error, 'Error creating user coupon:');
      throw error;
    }
};

export const deleteUserCouponAPI = async (id: number) => {
  try {
    await axiosInstance.delete(PATH_NAME.API_ADMIN_USER_COUPONS_BY_ID(id.toString()));
  } catch (error) {
    handleError(error, 'deleting user coupon');
    throw new Error('Failed to delete user coupon');
  }
};

export const updateUserCouponAPI = async (id: string, userCouponData: IUserCoupon): Promise<IUserCoupon> => {
  try {
    const response = await axiosInstance.put(PATH_NAME.API_ADMIN_USER_COUPONS_BY_ID(id), userCouponData);
    return response.data;
  } catch (error) {
    handleError(error,'Error updating voucher:');
    throw error;
  }
};

export const getUserCouponsAdmin = async (user_id: number) => {
  try {
      const response = await axiosInstance.get(PATH_NAME.API_ADMIN_USER_GET_USER_COUPON_ADMIN(user_id));
      if (response.data && response.data.result !== undefined) {
          return response.data.result;
      } else {
          console.error('Invalid response structure:', response.data);
          throw new Error('Invalid response structure');
      }
  } catch (error) {
      handleError(error, 'fetching reward point balance');
      throw new Error('Failed to fetch reward point balance');
  }
};

export const getUserResultCouponAdmin = async (userId: number) => {
  const data = await getUserCouponsAdmin(userId);
  return data.coupons;
};