import { PATH_NAME } from '../configs/pathName';
import { ISku, ISkuAPIResponse, ISkuDetails } from '../models/ISku';
import axiosInstance from './axiosInstance';

export const getAllSkus = async (): Promise<ISku[]> => {
    const response = await axiosInstance.get<ISkuAPIResponse>(PATH_NAME.API_GET_ALL_SKU);
    return response.data.result.items;
  };

export const getSkuDetailsBySku = async (sku: string) => {
  const response = await axiosInstance.get(PATH_NAME.API_ADMIN_GET_ADMIN_SKU(sku));
  return response.data;
};

export const getHQSkuDetailsBySku = async (sku: string) => {
  const response = await axiosInstance.get(PATH_NAME.API_ADMIN_GET_ADMIN_SKU(sku));
  return response.data.result;
};
  
// export const updateSkuDetails = async (sku_sku: string, details: Record<string, any>) => {
//   const response = await axiosInstance.put(PATH_NAME.API_ADMIN_SKU_DETAILS(sku_sku), details);
//   return response.data;
// };

export const updateSkuDetails = async (sku_sku: string, details: Record<string, any>) => {
  try {
    console.log('Making API call to:', PATH_NAME.API_ADMIN_SKU_DETAILS(sku_sku));
    console.log('Sending data:', details);
    
    const response = await axiosInstance.put(PATH_NAME.API_ADMIN_SKU_DETAILS(sku_sku), details);
    console.log('API response:', response);
    
    return response.data;
  } catch (error) {
    console.error('API call failed:', {
      url: PATH_NAME.API_ADMIN_SKU_DETAILS(sku_sku),
    });
    throw error;
  }
};

export const deleteSkuDetails = async (sku_sku: string): Promise<void> => {
    return axiosInstance.delete(PATH_NAME.API_ADMIN_SKU_DETAILS(sku_sku));
};

export const createSkuDetails = async (skuDetails: Partial<ISkuDetails>): Promise<ISkuDetails> => {
  try {
    const response = await axiosInstance.post<ISkuDetails>(
      PATH_NAME.API_ADMIN_POST_SKU_DETAILS,
      skuDetails
    );
    return response.data;
  } catch (error: any) {
    throw new Error(error.response?.data?.error_message || "Failed to create SKU details");
  }
};
