import React from 'react';
import { useParams } from 'react-router-dom';
import { Box, Typography, CircularProgress } from '@mui/material';
import useSWR from 'swr';
import { getUserByIdAPI } from '../../../../api/UserAPI';
import UserLayoutPage from './UserLayoutPage';
import UserGeneralInfo from './UserGeneralTab';
import UserOrders from './UserOrdersTab';
import UserPayments from './UserPaymentTab';
import UserRewards from './UserRewardTab';
import UserMessages from './UserMessagePage';
import StoreCreditTransaction from './component/UserWalletTab';
import { getStoreCreditByUserIdAPI } from '../../../../api/StoreCreditAPI';
import UserCoupons from './UserCouponsTab';

const UserDetails: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const userId = id ? parseInt(id, 10) : null;

  const {
    data: user,
    error: userError,
    isValidating: loadingUser,
  } = useSWR(userId ? `getUserByIdAPI${userId}` : null, 
    () => getUserByIdAPI(id!), 
    {
      revalidateOnFocus: false, // Prevent reload when switching tabs
      revalidateOnReconnect: false, // Prevent reload when network reconnects
      revalidateIfStale: false, // Prevent reload when tab regains focus
    }
  );
  
  const {
    data: storeCreditData,
    error: storeCreditError,
    isValidating: loadingStoreCredit,
  } = useSWR(userId ? `getStoreCreditByUserIdAPI${userId}` : null, 
    () => userId ? getStoreCreditByUserIdAPI(userId) : null, 
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      revalidateIfStale: false,
    }
  );

  const storeCredit = storeCreditData?.result.items[0];

  if (loadingUser || loadingStoreCredit) return <CircularProgress />;
  if (userError) return <Typography color="error">{`Failed to fetch user with ID ${id}`}</Typography>;
  if (storeCreditError) return <Typography color="error">{`Failed to fetch store credit for user ID ${id}`}</Typography>;

  const tabs = [
    {
      label: 'General',
      content: user && storeCredit ? (
        <UserGeneralInfo user={user} storeCredit={storeCredit} userId={userId!} />
      ) : (
        <Typography>Loading...</Typography>
      ),
    },
    {
      label: 'Orders',
      content: <UserOrders userId={userId!} />,
    },
    {
      label: 'Payments',
      content: <UserPayments userId={userId!} />,
    },
    {
      label: 'Reward Points',
      content: <UserRewards userId={userId!} />,
    },
    {
      label: 'Communication',
      content: <UserMessages userId={userId!} />,
    },
    {
      label: 'Wallet',
      content: <StoreCreditTransaction userId={userId!} />,
    },
    {
      label: 'Coupons',
      content: <UserCoupons userId={userId!} />,
    },
  ];

  const title = 'User Details';
  const backLink = '/Users';

  return (
    <div>
      <UserLayoutPage title={title} backLink={backLink} tabs={tabs} />
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }} />
    </div>
  );
};

export default UserDetails;
