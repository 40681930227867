import { Box, Button, IconButton, Switch } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import useSWR, { useSWRConfig } from "swr";
import { IAssetItems, IAssets } from "../../../../models/IAssets";
import { useEffect, useState } from "react";
import { PATH_NAME } from "../../../../configs/pathName";
import DataTable from "../../../common/DataTable/DataTable";
import { AWS_URL } from "../../../../configs/constants";
import VideoConfirmationDialog from "./VideoConfirmationDialog";
import { enqueueSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import {
  activateAssetAPI,
  deleteAssetAPI,
  getAssetAPI,
} from "../../../../api/AssetAPI";

const VideoList = () => {
  const { mutate } = useSWRConfig();
  const navigate = useNavigate();
  const {
    data: videos,
    error,
    isLoading,
  } = useSWR(PATH_NAME.API_ADMIN_ASSETS, getAssetAPI);

  const [filteredVideos, setFilteredVideos] = useState<IAssets[]>([]);
  const [selectedVideo, setSelectedVideo] = useState<number | null>(null);
  const [isOpenDialog, setIsOpenDialog] = useState(false);

  const handleCreate = () => {
    navigate("add");
  };

  const handleEdit = (id: number) => {
    setSelectedVideo(id);
    navigate(`edit/${id}`);
  };

  const handleDeleteClick = (id: number | null) => {
    if (id === null) return;
    setSelectedVideo(id);
    setIsOpenDialog(true);
  };

  const handleDeleteConfirm = async () => {
    if (selectedVideo !== null) {
      try {
        await deleteAssetAPI(selectedVideo);
        await mutate(PATH_NAME.API_ADMIN_ASSETS);
        setSelectedVideo(null);
        enqueueSnackbar("Video deleted successfully!", { variant: "success" });
      } catch (error) {
        console.error("Failed to delete banner:", error);
        enqueueSnackbar("Failed to delete video!", { variant: "error" });
      }
    }
    setIsOpenDialog(false);
    setSelectedVideo(null);
  };

  const handleClose = () => {
    setIsOpenDialog(false);
    setSelectedVideo(null);
  };

  const handleToggleActive = async (id: number) => {
    await activateAssetAPI(id);
    await mutate(PATH_NAME.API_ADMIN_ASSETS);
  };

  useEffect(() => {
    if (videos) {
      const filtered = videos
        .filter((video: IAssets) => video.placement === "video")
        .sort((a: IAssets, b: IAssets) => {
          if (a.id === undefined || b.id === undefined) return 0;
          return a.id - b.id;
        });
      setFilteredVideos(filtered);
    }
  }, [videos]);

  const columns = [
    { field: "id", headerName: "ID", flex: 0 },
    {
      field: "items",
      headerName: "Videos",
      flex: 1,
      renderCell: (params: any) => (
        <Box sx={{ display: "flex", flexDirection: "row", gap: 1 }}>
          {Array.isArray(params.row.items) &&
            params.row.items.map((item: IAssetItems, index: number) => (
              <video
                key={index}
                src={`${AWS_URL}/${item.asset}`}
                style={{
                  width: 50,
                  height: 50,
                  objectFit: "cover",
                  borderRadius: 4,
                }}
                preload="metadata"
                controls={false}
                muted={true}
                playsInline={true}
              />
            ))}
        </Box>
      ),
    },
    { field: "placement", headerName: "Placement", flex: 1 },
    {
      field: "active",
      headerName: "Active",
      flex: 0,
      renderCell: (params: any) => (
        <Switch
          checked={params.row.is_active}
          onChange={() => handleToggleActive(params.row.id)}
        />
      ),
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 0,
      renderCell: (params: any) => (
        <div>
          <IconButton
            aria-label="edit"
            size="small"
            color="primary"
            onClick={() => handleEdit(params.row.id)}
          >
            <EditIcon fontSize="small" />
          </IconButton>
          <IconButton
            aria-label="delete"
            size="small"
            color="primary"
            onClick={() => handleDeleteClick(params.row.id)}
          >
            <DeleteIcon fontSize="small" />
          </IconButton>
        </div>
      ),
    },
  ];

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error loading videos</div>;

  return (
    <Box>
      <Box sx={{ display: "flex", justifyContent: "flex-end", mb: 2, gap: 1 }}>
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={handleCreate}
        >
          Add
        </Button>
      </Box>
      <DataTable
        rows={filteredVideos || []}
        columns={columns}
        getRowId={(row) => row.id}
        loading={isLoading}
      />
      <VideoConfirmationDialog
        open={isOpenDialog}
        onClose={handleClose}
        onConfirm={handleDeleteConfirm}
        id={selectedVideo}
      />
    </Box>
  );
};

export default VideoList;
