import React, { useState, ChangeEvent } from "react";
import {
  Box,
  Button,
  FormControl,
  IconButton,
  Select,
  MenuItem,
  Stack,
  TextField,
  Typography,
  InputLabel,
  Paper,
  SelectChangeEvent,
} from "@mui/material";
import {
  AWS_URL,
  useContentUrls,
  MAX_VIDEO_SIZE,
  MAX_IMAGE_SIZE,
} from "../../../../configs/constants";
import DeleteIcon from "@mui/icons-material/Delete";
import { IAssetItems } from "../../../../models/IAssets";
import { uploadFileAPI } from "../../../../api/APIBanners";
import { enqueueSnackbar } from "notistack";
import LoadingOverlay from "../../../common/LoadingScreen/LoadingOverlay";

interface VideoFormProps {
  bannerItem: IAssetItems;
  updateBannerItems: (bannerItems: IAssetItems, id: number) => void;
  bannerId: number;
}

const VideoForm = ({
  bannerItem,
  bannerId,
  updateBannerItems,
}: VideoFormProps) => {
  const [localBannerItem, setLocalBannerItem] = useState<IAssetItems>(bannerItem);
  const [isLoading, setIsLoading] = useState(false); // State to manage loading
  const contentUrls = useContentUrls();

  const handleFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const selectedFiles = Array.from(event.target.files);
      const file = selectedFiles[0];

      if (selectedFiles[0].size > MAX_VIDEO_SIZE) {
        enqueueSnackbar("File size is too large", { variant: "error" });
        return;
      }

      if (file.size > MAX_IMAGE_SIZE && file.type.startsWith("image")) {
        enqueueSnackbar("Image file size is too large", { variant: "error" });
        return;
      }

      setIsLoading(true); // Start loading
      try {
        const uploadedFile = await uploadFileAPI(file);
        const updatedItem = { ...localBannerItem, asset: uploadedFile };
        setLocalBannerItem(updatedItem);
        updateBannerItems(updatedItem, bannerId);
      } catch (error) {
        enqueueSnackbar("Failed to upload file", { variant: "error" });
      } finally {
        setIsLoading(false); // Stop loading
      }
    }
  };

  const handleRemoveVideo = () => {
    const updatedItem = { ...localBannerItem, asset: "" };
    setLocalBannerItem(updatedItem);
    updateBannerItems(updatedItem, bannerId);
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    const updatedItem = { ...localBannerItem, [name]: value };
    setLocalBannerItem(updatedItem);
    updateBannerItems(updatedItem, bannerId);
  };

  const handleSelectChange = (event: SelectChangeEvent<string>) => {
    const { name, value } = event.target;
    const updatedItem = { ...localBannerItem, [name]: value };
    setLocalBannerItem(updatedItem);
    updateBannerItems(updatedItem, bannerId);
  };

  if (!localBannerItem) return null;

  return (
    <>
      <LoadingOverlay open={isLoading} message="Uploading..." />
      <Paper
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: 2,
          alignItems: "center",
          padding: 2,
        }}
      >
        {localBannerItem.asset !== "" ? (
          <Box sx={{ position: "relative", width: "fit-content" }}>
            <Box
              sx={{
                width: "100%",
                maxWidth: 600,
                borderRadius: 1,
                overflow: "hidden",
                bgcolor: "background.paper",
                boxShadow: 1,
              }}
            >
              {localBannerItem.asset.endsWith(".mp4") ? (
                <video
                  src={`${AWS_URL}/${localBannerItem.asset}`}
                  controls
                  style={{ width: "100%", maxHeight: "400px" }}
                />
              ) : (
                <img
                  src={`${AWS_URL}/${localBannerItem.asset}`}
                  alt="Uploaded Asset"
                  style={{ width: "100%", maxHeight: "400px", objectFit: "contain" }}
                />
              )}

              <IconButton
                onClick={handleRemoveVideo}
                sx={{
                  position: "absolute",
                  top: 8,
                  right: 8,
                  bgcolor: "background.paper",
                  "&:hover": { bgcolor: "background.paper" },
                }}
              >
                <DeleteIcon />
              </IconButton>
            </Box>
          </Box>
        ) : (
          <Button
            variant="outlined"
            component="label"
            sx={{
              width: 300,
              height: 125,
              display: "flex",
              flexDirection: "column",
              gap: 1,
            }}
          >
            <input
              type="file"
              accept="video/*,image/*"
              hidden
              onChange={handleFileChange}
            />
            <Typography variant="body1">Click to Upload Video/Image</Typography>
            <Typography variant="caption" color="textSecondary">
              Maximum size: {(MAX_VIDEO_SIZE / (1024 * 1024)).toFixed(0)}MB
            </Typography>
          </Button>
        )}
        <Stack direction="row" spacing={1} sx={{ width: "100%", flex: 1 }}>
          <TextField
            multiline
            rows={4}
            label="Header Text"
            name="text"
            value={localBannerItem.text}
            onChange={handleChange}
          />
          <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
            <TextField
              fullWidth
              label="Button Text"
              value={localBannerItem.btn_text}
              name="btn_text"
              onChange={handleChange}
            />
            <FormControl fullWidth>
              <InputLabel>Button Link</InputLabel>
              <Select
                name="btn_url"
                label="Button Link"
                placeholder="Button Link"
                value={localBannerItem.btn_url}
                onChange={handleSelectChange}
              >
                {contentUrls.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Stack>
      </Paper>
    </>
  );
};

export default VideoForm;