import React, { useState } from 'react';
import {
    Container,
    Box,
    Typography,
    TextField,
    Button,
    Paper,
    IconButton,
    InputAdornment
} from '@mui/material';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';
import { toast } from '../../../components/common/SnackBar/snackBar';
import { sendOtpForSignup } from '../../../api/AuthAPI';
import { PATH_NAME } from '../../../configs/pathName';
import { Visibility, VisibilityOff } from '@mui/icons-material';

const RegisterPage: React.FC = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const UserRegisterData = location.state || {};

    const [phone, setPhone] = useState(UserRegisterData.phone || "");
    const [countryCode, setCountryCode] = useState('');
    const [otpSent, setOtpSent] = useState(false);
    const [password, setPassword] = useState(UserRegisterData.password || "");
    const [showPassword, setShowPassword] = useState(false);
    const [firstname, setFirstName] = useState(UserRegisterData.firstname || "");
    const [lastname, setLastName] = useState(UserRegisterData.lastname || "");

    const handlePhoneChange = (value: string, data: any) => {
        const phoneNumber = value.slice(data.dialCode.length);
        setPhone(phoneNumber);
        setCountryCode(`+${data.dialCode}`);
    };

    const handleSendOtp = async () => {
        const phoneNumber = phone.replace(/[^0-9]/g, "");

        if (phoneNumber && countryCode) {
            try {
                const response = await sendOtpForSignup(`${countryCode}${phoneNumber}`);
                if (response.success) {
                    setOtpSent(true);
                    toast.success("OTP sent successfully!");
                    navigate(PATH_NAME.VERIFY_OTP, {
                        state: { country_code: countryCode, mobile: phoneNumber, password, firstname, lastname }
                    });
                } else {
                    toast.error("Failed to send OTP.");
                }
            } catch (error) {
                toast.error("An unknown error occurred.");
            }
        } else {
            toast.error("Invalid phone number.");
        }
    };

    const handleRegister = async () => {
        if (!otpSent) {
            handleSendOtp();
        }
    };

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    return (
        <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
            <Container maxWidth="xs">
                <Paper elevation={0} sx={{ padding: 3, textAlign: 'center' }}>
                    <Typography variant="h4" component="h1" gutterBottom>
                        Create new Account
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                        Already Registered? <Link to={PATH_NAME.LOGIN}>Log in here</Link>
                    </Typography>
                    <Box sx={{ mt: 2 }}>
                        <TextField
                            required
                            fullWidth
                            id="firstname"
                            name="firstname"
                            label="First Name"
                            value={firstname}
                            onChange={(e) => setFirstName(e.target.value)}
                            margin="normal"
                        />
                        <TextField
                            required
                            fullWidth
                            id="lastname"
                            name="lastname"
                            label="Last Name"
                            value={lastname}
                            onChange={(e) => setLastName(e.target.value)}
                            margin="normal"
                        />
                        <PhoneInput
                            country={'sg'}
                            value={countryCode + phone}
                            onChange={handlePhoneChange}
                            inputProps={{
                                name: 'phone',
                                required: true,
                                autoFocus: true,
                            }}
                            containerStyle={{ width: "100%", marginTop: "16px" }}
                            inputStyle={{ width: "100%" }}
                        />
                        <TextField
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type={showPassword ? "text" : "password"}
                            id="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            margin="normal"
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton onClick={handleClickShowPassword} edge="end">
                                            {showPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            onClick={handleRegister}
                            sx={{ mt: 3, mb: 2, backgroundColor: '#ff5e2c', '&:hover': { backgroundColor: '#ff5e2c' } }}
                            >
                            Sign up
                        </Button>
                    </Box>
                </Paper>
            </Container>
        </Box>
    );
};

export default RegisterPage;
