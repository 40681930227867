import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from '../../common/SnackBar/snackBar';
import useSWR, { mutate } from 'swr';
import { getContactFormByIdAPI, updateContactFormAPI } from '../../../api/ContactFormAPI';
import { IContactForm } from '../../../models/IContactForm';
import ContactForm from '../../common/ContactForm/ContactForm';

const EditContactFormPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [contactForm, setContactForm] = useState<IContactForm | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const { data: contactformData, error } = useSWR(
    id ? `getContactFormByIdAPI/${id}` : null,
    () => getContactFormByIdAPI(id!).then((response) => response)
  );

  useEffect(() => {
    if (contactformData) {
      setContactForm(contactformData);
    }
  }, [contactformData]);

  const handleSave = async (updatedContactForm: IContactForm) => {
    if (!id) return;

    setIsLoading(true);
    try {  
      mutate(`getContactFormByIdAPI/${id}`, updatedContactForm, false);
      await updateContactFormAPI(updatedContactForm);
      toast.success('Contact Form updated successfully');
      navigate('/Content/HomePageContent/contact_form');
    } catch (error) {
      toast.error('Error updating Contacr form');
    } finally {
      setIsLoading(false);
    }
  };

  const handleCancel = () => {
    navigate('/Content/HomePageContent/contact_form');
  };

  if (error) return <div>Error loading Contact form data</div>;
  if (!contactForm) return <div>Loading...</div>;

  return (
    <div>
      <h2>Contact Form</h2>
      <ContactForm
        contactForm={contactForm}
        onSave={handleSave}
        onCancel={handleCancel}
        isLoading={isLoading}
      />
    </div>
  );
};

export default EditContactFormPage;
