import { useState, useEffect } from 'react';
import RoleForm from '../../../common/RoleForm/RoleForm';
import UserLayoutPage from '../../System/Users/UserLayoutPage';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { IRole } from '../../../../models/IRole';
import { toast } from '../../../common/SnackBar/snackBar';
import useSWR from 'swr';
import { getRoleByIdAPI, updateRoleAPI } from '../../../../api/RoleAPI';

const EditRolePage = () => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const [isLoading, setIsLoading] = useState(false);

  const { data: role, error } = useSWR(
    id ? `getRoleByIdAPI/${id}` : null,
    () => getRoleByIdAPI(id!).then((response) => {
      return response;
    })
  );

  const [roleForm, setRoleForm] = useState<IRole>({
    id: 0,
    name: '',
    permissions: [], // Array of permission IDs
    tenant_id: 0,
    is_admin: false,
    is_hq_admin: false,
    is_deleted: false,
    created_at: '',
    updated_at: '',
    deleted_at: null,
    created_by: 0,
    updated_by: 0,
    deleted_by: null,
  });

  useEffect(() => {
    if (role) {
      setRoleForm(role);
    }
  }, [role]);

  const handleSave = async (role: IRole) => {
    setIsLoading(true);
    try {
      await updateRoleAPI(role);
      toast.success('Role updated successfully');
      navigate('/System/Roles');
    } catch (error) {
      toast.error('Error updating role');
    }
  };

  const handleCancel = () => {
    navigate('/System/Roles');
  };

  const title = 'Edit Role';
  const backLink = '/System/Roles';

  const tabs = [
    {
      label: 'General',
      content: (
        <RoleForm
          roleForm={roleForm}
          onSave={handleSave}
          onCancel={handleCancel}
          isLoading={isLoading}  
        />
      ),
    },
  ];

  if (error) {
    return <div>Error loading role data</div>;
  }

  return (
    <div>
      <UserLayoutPage title={title} backLink={backLink} tabs={tabs} />
    </div>
  );
};

export default EditRolePage;