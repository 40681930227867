import { PATH_NAME } from "../configs/pathName";
import { handleError } from "../helpers/Fetchers/fetchers";
import { ICollection, ICollectionCreate } from "../models/ICollection";
import axiosInstance from "./axiosInstance";

export const createCollectionAPI = async (collection: ICollectionCreate) => {
  try {
    const response = await axiosInstance.post(PATH_NAME.API_ADMIN_COLLECTION_CREATE, collection);
    return response.data;
  } catch (error) {
    handleError(error, 'Error creating collection:');
    throw error;
  }
};

export const updateCollectionAPI = async (id: string, collection: ICollection) => {
  try {
    const response = await axiosInstance.put(PATH_NAME.API_ADMIN_COLLECTION_UPDATE(id), collection);
    return response.data;
  } catch (error) {
    handleError(error, 'Error updating collection:');
    throw error;
  }
};

export const deleteCollectionAPI = async (id: string) => {
  try {
    const response = await axiosInstance.delete(PATH_NAME.API_ADMIN_COLLECTION_DELETE(id));
    return response.data;
  } catch (error) {
    handleError(error, 'Error deleting collection:');
    throw error;
  }
};
