import { PATH_NAME } from "../configs/pathName";
import { handleError } from "../helpers/Fetchers/fetchers";
import { IButtonLink } from "../models/IButtonLink";
import axiosInstance from "./axiosInstance";

export const ButtonLinkAPI = async (key: string) => {
  try {
    const response = await axiosInstance.get(key);
    return response.data;
  } catch (error) {
    handleError(error, 'fetching button links');
    throw new Error('Failed to fetch button links');
  }
};

export const createNewButtonLinkAPI = async (buttonLink: IButtonLink): Promise<IButtonLink> => {
  try {
    const response = await axiosInstance.post(PATH_NAME.API_ADMIN_BUTTON_LINK, buttonLink);
    if (response.data && response.data.result) {
      return response.data.result;
    } else {
      throw new Error('Failed to create voucher');
    }
  } catch (error) {
    handleError(error,'Error creating voucher:');
    throw error;
  }
};

export const getButtonLinkByIdAPI = async (id: string): Promise<IButtonLink> => {
  try {
    const response = await axiosInstance.get(PATH_NAME.API_ADMIN_GET_BUTTON_LINK_BY_ID(id));
    if (response.data.success) {
      return response.data.result;
    } else {
      throw new Error('Failed to fetch Button');
    }
  } catch (error) {
    handleError(error,`Error fetching Button with id ${id}:`);
    throw error;  
  }
};  

export const deleteButtonLinkAPI = async (id: number) => {
  try {
    await axiosInstance.delete(PATH_NAME.API_ADMIN_GET_BUTTON_LINK_BY_ID(id.toString()));
  } catch (error) {
    handleError(error, `deleting Button with ID ${id}`);
    throw error;
  }
};

export const updateButtonLinkAPI = async (button: IButtonLink): Promise<IButtonLink> => {
  try {
    // Ensure the button data is sent in the body of the PUT request
    const response = await axiosInstance.put(PATH_NAME.API_ADMIN_GET_BUTTON_LINK_BY_ID(button.id.toString()), button);
    return response.data;  // Return the response data if successful
  } catch (error) {
    // Handle error and throw
    handleError(error, 'updating button');
    throw error;
  }
};