import * as React from 'react';
import { Box, Typography, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import BasicTabs from '../../../common/Tab/BasicTab';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

interface UserLayoutPageProps {
  title: string;
  backLink: string;
  tabs: { label: string; content: React.ReactNode }[];
}

const SkuLayoutPage: React.FC<UserLayoutPageProps> = ({ title, backLink, tabs }) => {
  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate(backLink);
  };

  return (
    <Box>
      <Box sx={{ display: 'flex', alignItems: 'center', pb: 2 }}>
        <Button
          onClick={handleBackClick}
          startIcon={<ArrowBackIosIcon />}
          sx={{
            borderWidth: '1px',
            borderRadius: '30px',
            borderStyle: 'solid',
            padding: '5px 20px'
          }}
        >
          BACK
        </Button>
      </Box>
      <Box sx={{ padding: 0, border: '1px solid #dddddd' }}>
        <div className="TitleSection">
          <Typography variant="h6" sx={{ padding: 2 }}>{title}</Typography>
        </div>
        <BasicTabs tabs={tabs} />
      </Box>
    </Box>
  );
};

export default SkuLayoutPage;
