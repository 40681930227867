import { PATH_NAME } from "../configs/pathName";
import { handleError } from "../helpers/Fetchers/fetchers";
import { IContactForm } from "../models/IContactForm";
import axiosInstance from "./axiosInstance";

export const ContactFormAPI = async (key: string) => {
  try {
    const response = await axiosInstance.get(key);
    return response.data;
  } catch (error) {
    handleError(error, 'fetching Contact Form');
    throw new Error('Failed to fetch Contact Form');
  }
};

export const getContactFormByIdAPI = async (id: string): Promise<IContactForm> => {
  try {
    const response = await axiosInstance.get(PATH_NAME.API_ADMIN_GET_CONTACT_FORM_BY_ID(id));
    if (response.data.success) {
      return response.data.result;
    } else {
      throw new Error('Failed to fetch Contact Form');
    }
  } catch (error) {
    handleError(error,`Error fetching Contact Form with id ${id}:`);
    throw error;  
  }
};  

export const deleteContactFormAPI = async (id: number) => {
  try {
    await axiosInstance.delete(PATH_NAME.API_ADMIN_GET_CONTACT_FORM_BY_ID(id.toString()));
  } catch (error) {
    handleError(error, `deleting Contact form with ID ${id}`);
    throw error;
  }
};

export const updateContactFormAPI = async (contact: IContactForm): Promise<IContactForm> => {
  try {
    // Ensure the button data is sent in the body of the PUT request
    const response = await axiosInstance.put(PATH_NAME.API_ADMIN_GET_CONTACT_FORM_BY_ID(contact.id.toString()), contact);
    return response.data;  // Return the response data if successful
  } catch (error) {
    // Handle error and throw
    handleError(error, 'updating contact form');
    throw error;
  }
};