import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { toast } from '../../../../common/SnackBar/snackBar';
import { createSkuDetails, getSkuDetailsBySku } from '../../../../../api/GetAllSkuAPI';
import SkuForm from '../../../../common/SkuForm/SkuForm';
import { ISkuDetails } from '../../../../../models/ISku';

const defaultSku: ISkuDetails = {
  short_name: '',
  name: '',
  sku_sku: '',
  sales_ranking: 0,
  collections: [],
  batches: [],
  price: 0,
  description: '',
  ingredients:'',
  nutrition:{},
  storage_info:'',
  feature_logos:[],
  image_url: '',
  additional_image_urls: [],
  weight: '',
  size: '',
  average_rating: 0,
  review_count: 0,
  allergen:'',
  slug:'',
};

const AddSkuDetails: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();

  // Get SKU from navigation state
  const { state } = location;
  const skuFromList = state?.sku || '';

  const [skuForm, setSkuForm] = useState<ISkuDetails>({
    ...defaultSku,
    sku_sku: skuFromList,
  });
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchSkuDetails = async () => {
      if (skuFromList) {
        setIsLoading(true);
        try {
          const details = await getSkuDetailsBySku(skuFromList);
          setSkuForm({ ...defaultSku, ...details, sku_sku: skuFromList });
        } catch (error) {
          toast.error('Failed to fetch SKU details');
        } finally {
          setIsLoading(false);
        }
      }
    };

    fetchSkuDetails();
  }, [skuFromList]);

  const handleSave = async (sku: ISkuDetails) => {
    setIsLoading(true);
    try {
      const updatedSku = {
        ...sku,
        collections: sku.collections || [],
      };
  
      await createSkuDetails(updatedSku);
      toast.success('SKU details added successfully');
      navigate(`/product/skus_details/${sku.sku_sku}`);
    } catch (error) {
      toast.error('Error adding SKU details');
    } finally {
      setIsLoading(false);
    }
  };
  
  const handleCancel = () => {
    navigate('/product/skus');
  };

  return (
    <div>
      <SkuForm
        skuForm={skuForm}
        onSave={handleSave}
        onCancel={handleCancel}
        isLoading={isLoading}
      />
    </div>
  );
};

export default AddSkuDetails;
