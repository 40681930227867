import React, { useMemo, useState } from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton } from '@mui/material';
import useSWR, { mutate } from 'swr';
import { PATH_NAME } from '../../../configs/pathName';
import DataTable from '../../common/DataTable/DataTable';
import { useNavigate } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';
import { ContactFormAPI, deleteContactFormAPI } from '../../../api/ContactFormAPI';
import VisibilityIcon from '@mui/icons-material/Visibility';

const ContactFormTable: React.FC = () => {
    const navigate = useNavigate();

  const { data: contactForms = [], error, isLoading } = useSWR(
    `${PATH_NAME.API_ADMIN_CONTACT_FORM}`,
    ContactFormAPI
  );

  const [open, setOpen] = useState(false);
  const [selectedContactForm, setSelectedContactForm] = useState<number | null>(null);
    
  const sortedContactForm = useMemo(() => {
    return contactForms.result?.items
      ? [...contactForms.result.items].sort((a, b) => b.id - a.id)
      : [];
  }, [contactForms]);
  
  
  const columns = [
    { field: 'id', headerName: 'ID', flex: 1 },
    { field: 'name', headerName: 'Name', flex: 2 },
    { field: 'mobile_no', headerName: 'Mobile No', flex: 2 },
    { field: 'email', headerName: 'Email', flex: 2 },
    // { field: 'message', headerName: 'Message', flex: 2 },
    { field: 'remarks', headerName: 'Remarks', flex: 2 },
    { field: 'status', headerName: 'Status', flex: 2 },

    {
    field: 'actions', headerName: 'Actions', flex: 1, renderCell: (params: any) => (
      <div>
        <IconButton
          aria-label="edit"
          size="small"
          color="primary"
          onClick={() => handleEdit(params.row.id)}
        >
          <VisibilityIcon fontSize="small" />
        </IconButton>
        <IconButton
          aria-label="delete"
          size="small"
          color="primary"
          onClick={() => handleDeleteClick(params.row.id)}
        >
          <DeleteIcon fontSize="small" />
        </IconButton>
      </div>
    ),
  },
  ];

  const handleEdit = (id: number) => {
    navigate(`/Content/HomePageContent/contact_form/edit/${id}`);
  };

  const handleDeleteClick = (id: number) => {
    setSelectedContactForm(id);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedContactForm(null);
  };

  const handleDeleteConfirm = async () => {
      if (selectedContactForm !== null) {
        try {
          await deleteContactFormAPI(selectedContactForm);
          await mutate(PATH_NAME.API_ADMIN_CONTACT_FORM);
        } catch (error) {
          console.error('Failed to delete role:', error);
        }
      }
      setOpen(false);
      setSelectedContactForm(null);
    };

    

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error loading Button Links</div>;

  return (
    <div>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2, gap: 1 }}>
      </Box>
      <DataTable
        rows={sortedContactForm}
        columns={columns}
        getRowId={(row) => row.id}
        loading={isLoading}
      />
      <Dialog
        open={open}
        onClose={handleClose}
      >
        <DialogTitle>{"Confirm Deletion"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this Contact Form?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeleteConfirm} color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ContactFormTable;
