import * as React from "react";
import { Tabs, Tab, Box } from "@mui/material";

interface TabItem {
  label: string;
  value: string;
}

interface BasicTabsProps {
  tabs: TabItem[];
  currentTab: string;
  onTabChange: (newValue: string) => void;
}

export default function BasicTabs({
  tabs,
  currentTab,
  onTabChange,
}: BasicTabsProps) {
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    onTabChange(newValue);
  };

  return (
    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
      <Tabs value={currentTab} onChange={handleChange} aria-label="basic tabs">
        {tabs.map((tab) => (
          <Tab key={tab.value} label={tab.label} value={tab.value} />
        ))}
      </Tabs>
    </Box>
  );
}
