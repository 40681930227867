import React, { useEffect, useState } from "react";
import { styled, Box, IconButton, Typography, Stack, Button } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { useSnackbar } from "notistack";
import { uploadFile } from "../../../../api/FileUploadAPI";
import { AWS_URL, MAX_FILE_SIZE } from "../../../../configs/constants";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

interface AdditionalImagePreviewProps {
  onImageUrlsChange: (urls: string[]) => void;
  value: string[];
}

const AdditionalImagePreview = ({ onImageUrlsChange, value }: AdditionalImagePreviewProps) => {
  const { enqueueSnackbar } = useSnackbar();

  // Combine initial image URLs and newly uploaded ones
  const [additionalImagePreviews, setAdditionalImagePreviews] = useState<string[]>(
    value.map((url) => `${AWS_URL}/${url}`) || []
  );

  useEffect(() => {
    // Always update the previews when `value` changes (e.g., on save changes)
    const initialPreviews = value.map((url) => `${AWS_URL}/${url}`);
    setAdditionalImagePreviews(initialPreviews);
  }, [value]); // Re-run whenever `value` changes

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const selectedFiles = Array.from(event.target.files);

      // Only handle files up to the max size
      const validFiles = selectedFiles.filter((file) => file.size <= MAX_FILE_SIZE);
      if (validFiles.length !== selectedFiles.length) {
        enqueueSnackbar("Some files were too large and were not uploaded.", {
          variant: "warning",
        });
      }

      // Upload the files and get the image URLs
      try {
        const fileNames: string[] = [];
        for (const file of validFiles) {
          const uploadedFile = await uploadFile(file);
          const fileName = uploadedFile; // Assuming `uploadFile` returns the filename
          fileNames.push(fileName);
        }

        const newPreviews = fileNames.map((name) => `${AWS_URL}/${name}`);
        setAdditionalImagePreviews((prevPreviews) => [...prevPreviews, ...newPreviews]);

        // Update the parent with the new URLs
        onImageUrlsChange([...value, ...fileNames]);
        enqueueSnackbar("Files uploaded successfully!", { variant: "success" });
      } catch (error) {
        enqueueSnackbar("Failed to upload files.", { variant: "error" });
      }
    }
  };

  const handleRemoveImage = (index: number) => {
    setAdditionalImagePreviews((prevPreviews) => prevPreviews.filter((_, i) => i !== index));
    const updatedValue = value.filter((_, i) => i !== index);
    onImageUrlsChange(updatedValue);
  };

  return (
    <Stack direction="column" sx={{ border: "1px solid #e0e0e0", p: 2, width: "100%" , height:"200px" , justifyContent:"center"}}>
      <Typography>Additional Image</Typography>
      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1}}>
        {additionalImagePreviews.map((preview, index) => (
          <Box
            key={index}
            sx={{
              width: 100,
              height: 100,
              border: "1px dashed #ccc",
              position: "relative",
              backgroundColor: "#f5f5f5",
            }}
          >
            <img
              src={preview}
              alt={`Preview ${index}`}
              style={{ width: "100%", height: "100%", objectFit: "contain" }}
            />
            <IconButton
              size="small"
              onClick={() => handleRemoveImage(index)}
              sx={{
                position: "absolute",
                top: 0,
                right: 0,
                background: "rgba(0, 0, 0, 0.5)",
                color: "white",
              }}
            >
              <DeleteIcon />
            </IconButton>
          </Box>
        ))}

        <Box
          sx={{
            width: 100,
            height: 100,
            border: "1px dashed #ccc",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#f5f5f5",
          }}
        >
          <Button component="label" variant="outlined" sx={{ height: "100%", width: "100%" }}>
            +
            <VisuallyHiddenInput type="file" multiple accept="image/*" onChange={handleFileChange} />
          </Button>
        </Box>
      </Box>
    </Stack>
  );
};

export default AdditionalImagePreview;
