import React, { useMemo, useState } from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton } from '@mui/material';
import useSWR, { mutate } from 'swr';
import { PATH_NAME } from '../../../configs/pathName';
import DataTable from '../../common/DataTable/DataTable';
import { ButtonLinkAPI, deleteButtonLinkAPI } from '../../../api/ButtonLink';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

const ButtonLinkTable: React.FC = () => {
    const navigate = useNavigate();

  const { data: buttonLinks = [], error, isLoading } = useSWR(
    `${PATH_NAME.API_ADMIN_BUTTON_LINK}`,
    ButtonLinkAPI
  );

    const [open, setOpen] = useState(false);
    const [selectedButton, setSelectedButton] = useState<number | null>(null);
  
    
  const sortedButtonLinks = useMemo(() => {
    return buttonLinks.result?.items
      ? [...buttonLinks.result.items].sort((a, b) => a.id - b.id)
      : [];
  }, [buttonLinks]);

  const columns = [
    { field: 'id', headerName: 'ID', flex: 1 },
    { field: 'name', headerName: 'Name', flex: 2 },
    { field: 'url', headerName: 'URL', flex: 2 },
    {
        field: 'actions', headerName: 'Actions', flex: 1, renderCell: (params: any) => (
          <div>
            <IconButton
              aria-label="edit"
              size="small"
              color="primary"
              onClick={() => handleEdit(params.row.id)}
            >
              <EditIcon fontSize="small" />
            </IconButton>
            <IconButton
              aria-label="delete"
              size="small"
              color="primary"
              onClick={() => handleDeleteClick(params.row.id)}
            >
              <DeleteIcon fontSize="small" />
            </IconButton>
          </div>
        ),
      },
  ];

  const handleEdit = (id: number) => {
    navigate(`/Content/HomePageContent/button_link/edit/${id}`);
  };

  const handleCreate = () => {
    navigate('/Content/HomePageContent/button_link/add');
  };


  const handleDeleteClick = (id: number) => {
    setSelectedButton(id);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedButton(null);
  };

  const handleDeleteConfirm = async () => {
      if (selectedButton !== null) {
        try {
          await deleteButtonLinkAPI(selectedButton);
          await mutate(PATH_NAME.API_ADMIN_BUTTON_LINK);
        } catch (error) {
          console.error('Failed to delete role:', error);
        }
      }
      setOpen(false);
      setSelectedButton(null);
    };

    

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error loading Button Links</div>;

  return (
    <div>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2, gap: 1 }}>
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={handleCreate}
        >
          Add
        </Button>
      </Box>
      <DataTable
        rows={sortedButtonLinks}
        columns={columns}
        getRowId={(row) => row.id}
        loading={isLoading}
      />
      <Dialog
        open={open}
        onClose={handleClose}
      >
        <DialogTitle>{"Confirm Deletion"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this Button?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeleteConfirm} color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ButtonLinkTable;
