import React, { useState } from "react";
import {
  Box,
  Button,
  Typography,
  TextField,
  IconButton,
  Stack,
  CircularProgress,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { IAssetItems } from "../../../../models/IAssets";
import { addAssetAPI } from "../../../../api/AssetAPI";

const AnnouncementAdd: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [announcementList, setAnnouncementList] = useState<IAssetItems[]>([
    {
      asset: "",
      text: "",
      text_color: "",
      btn_text: "",
      btn_url: "",
      position: "",
    },
  ]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleAddField = () => {
    setAnnouncementList([
      ...announcementList,
      {
        asset: "",
        text: "",
        text_color: "",
        btn_text: "",
        btn_url: "",
        position: "",
      },
    ]);
  };

  const handleRemoveField = (index: number) => {
    const newList = announcementList.filter((_, i) => i !== index);
    setAnnouncementList(newList);
  };

  const handleAnnouncementChange = (index: number, value: string) => {
    const newList = [...announcementList];
    newList[index] = {
      ...newList[index],
      text: value,
    };
    setAnnouncementList(newList);
  };

  const handleSubmit = async () => {
    const filteredAnnouncements = announcementList.filter(
      (announcement) => announcement.text.trim() !== ""
    );

    if (filteredAnnouncements.length === 0) {
      enqueueSnackbar("Please add at least one announcement", {
        variant: "warning",
      });
      return;
    }

    try {
      setIsSubmitting(true);
      await addAssetAPI({
        items: filteredAnnouncements,
        placement: "announcement",
      });
      enqueueSnackbar("Announcement added successfully", {
        variant: "success",
      });
      navigate(-1);
    } catch (error) {
      enqueueSnackbar("Failed to add announcement", {
        variant: "error",
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Box sx={{ maxWidth: 800, margin: "0 auto" }}>
      <Stack direction="row" alignItems="center" spacing={2} mb={4}>
        <IconButton onClick={() => navigate(-1)}>
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="h5">Add New Announcement List</Typography>
      </Stack>

      <Box sx={{ mb: 4 }}>
        <Stack spacing={2}>
          {announcementList.map((announcement, index) => (
            <Stack
              key={index}
              direction="row"
              alignItems="center"
              spacing={1}
              sx={{
                backgroundColor: "background.paper",
                p: 2,
                borderRadius: 1,
                boxShadow: 1,
              }}
            >
              <TextField
                fullWidth
                label={`Announcement ${index + 1}`}
                value={announcement.text}
                onChange={(e) =>
                  handleAnnouncementChange(index, e.target.value)
                }
                multiline
                rows={2}
                sx={{ flex: 1 }}
              />
              {announcementList.length > 1 && (
                <IconButton
                  onClick={() => handleRemoveField(index)}
                  color="error"
                  size="small"
                  sx={{ alignSelf: "flex-start" }}
                >
                  <DeleteOutlineIcon />
                </IconButton>
              )}
            </Stack>
          ))}

          <Button
            startIcon={<AddCircleOutlineIcon />}
            onClick={handleAddField}
            sx={{
              alignSelf: "flex-start",
              mt: 2,
            }}
          >
            Add Another Announcement
          </Button>
        </Stack>
      </Box>

      <Stack direction="row" spacing={2} justifyContent="flex-end">
        <Button variant="outlined" onClick={() => navigate(-1)}>
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={handleSubmit}
          disabled={
            isSubmitting || announcementList.every((a) => !a.text.trim())
          }
        >
          {isSubmitting ? <CircularProgress size={24} /> : "Add Announcements"}
        </Button>
      </Stack>
    </Box>
  );
};

export default AnnouncementAdd;
