import axiosInstance from "./axiosInstance";

import { PATH_NAME } from "../configs/pathName";
import { IAssetItems, IAssets } from "../models/IAssets";

export const getAssetAPI = async () => {
  try {
    const response = await axiosInstance.get(PATH_NAME.API_ADMIN_ASSETS);
    return response.data;
  } catch (error) {
    console.error('Error fetching assets:', error);
    throw new Error('Failed to fetch assets');
  }
}

export const addAssetAPI = async (data: { placement: string, items?: IAssetItems[], is_active?: boolean }): Promise<void> => {
  try {
    if (data.is_active === undefined) {
      data.is_active = false;
    }
    await axiosInstance.post(PATH_NAME.API_ADMIN_ASSETS, data);
  } catch (error) {
    console.error('Error adding asset:', error);
    throw new Error('Failed to add asset');
  }
}

export const getAssetByIdAPI = async (id: string): Promise<IAssets> => {
  try { 
    const response = await axiosInstance.get(PATH_NAME.API_ADMIN_ASSETS_BY_ID(id));
    return response.data;
  } catch (error) {
    console.error('Error fetching asset:', error);
    throw new Error('Failed to fetch asset');
  }
}

export const updateAssetAPI = async (id: string, data: { placement: string, items?: IAssetItems[], is_active?: boolean }): Promise<void> => {
  try {
    await axiosInstance.put(PATH_NAME.API_ADMIN_ASSETS_BY_ID(id), data);
  } catch (error) {
    console.error('Error updating asset:', error);
    throw new Error('Failed to update asset');
  }
}

export const activateAssetAPI = async (id: number): Promise<void> => {
  try {
    await axiosInstance.post(PATH_NAME.API_ADMIN_ASSETS_ACTIVATE(id));
  } catch (error) {
    console.error('Error activating asset:', error);
    throw new Error('Failed to activate asset');
  }
}

export const deleteAssetAPI = async (id: number): Promise<void> => {
  try {
    await axiosInstance.delete(PATH_NAME.API_ADMIN_ASSETS_BY_ID(id.toString()));
  } catch (error) {
    console.error('Error deleting asset:', error);
    throw new Error('Failed to delete asset');
  }
}
