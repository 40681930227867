import axiosInstance from './axiosInstance';
import { PATH_NAME } from '../configs/pathName';

export const uploadFileAPI = async (file: File): Promise<string> => {
  const formData = new FormData();
  formData.append('file', file);

  try {
    const response = await axiosInstance.post(PATH_NAME.API_ADMIN_FILE_UPLOAD, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    if (response.data.success && response.data.result && response.data.result.file_name) {
      const fileName = response.data.result.file_name;

      const fileUrl = `${fileName}`;
      return fileUrl;
    } else {
      throw new Error('File upload failed: URL not found in response');
    }
  } catch (error) {
    console.error('Error uploading file:', error);
    throw new Error('File upload failed');
  }
};
