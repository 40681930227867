import { TextField, Typography, Grid, FormControl } from "@mui/material";

interface CollectionTextFieldGroupProps {
  label: string;
  value: string | number;
  name: string;
  disabled?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const CollectionTextFieldGroup = ({
  label,
  value,
  name,
  disabled = false,
  onChange,
}: CollectionTextFieldGroupProps) => {
  const inputValue = value || "";
  return (
    <Grid item container xs={12} justifyContent="flex-end" alignItems="center">
      <Grid item xs={2}>
        <Typography variant="subtitle2" fontWeight="bold">
          {label}
        </Typography>
      </Grid>

      <Grid item xs>
        <FormControl fullWidth margin="none" size="small">
          <TextField
            label={label}
            name={name}
            value={inputValue}
            size="small"
            fullWidth
            required
            disabled={disabled}
            onChange={onChange}
          />
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default CollectionTextFieldGroup;
