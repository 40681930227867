import React from "react";
import SkuLayoutPage from "../SkuLayoutPage";
import AddSkuDetails from "./AddSkuDetails";

const AddSkuDetailsPage: React.FC = () => {
  const tabs = [
    {
      label: "SKU Details",
      content: <AddSkuDetails />,
    },
  ];

  const title = "Add SKU Details";
  const backLink = "/product/skus";

  return (
    <div>
      <SkuLayoutPage title={title} backLink={backLink} tabs={tabs} />
    </div>
  );
};

export default AddSkuDetailsPage;
