import React from 'react';
import { Box, Button, TextField } from '@mui/material';
import { IButtonLink } from '../../../models/IButtonLink';

interface ButtonLinkFormProps {
  buttonLinkForm: IButtonLink;
  onSave: (buttonLink: IButtonLink) => void;
  onCancel: () => void;
  isLoading: boolean;
}

const ButtonLinkForm: React.FC<ButtonLinkFormProps> = ({
  buttonLinkForm,
  onSave,
  onCancel,
  isLoading,
}) => {
  const [formData, setFormData] = React.useState(buttonLinkForm);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSave(formData);
  };

  return (
    <Box component="form" onSubmit={handleSubmit}>
      <TextField
        label="Name"
        name="name"
        fullWidth
        margin="normal"
        value={formData.name}
        onChange={handleInputChange}
      />
      <TextField
        label="URL"
        name="url"
        fullWidth
        margin="normal"
        value={formData.url}
        onChange={handleInputChange}
      />
      <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
        <Button variant="outlined" color="primary" onClick={onCancel} disabled={isLoading}>
          Cancel
        </Button>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          sx={{ ml: 1 }}
          disabled={isLoading}
        >
          {isLoading ? 'Saving...' : 'Save'}
        </Button>
      </Box>
    </Box>
  );
};

export default ButtonLinkForm;
