import React, { useState } from "react";
import { Box, Typography, Divider, Button, IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ICollection } from "../../../../models/ICollection";
import { generalItemFetcher } from "../../../../helpers/Fetchers/fetchers";
import useSWR, { mutate } from "swr";
import { PATH_NAME } from "../../../../configs/pathName";
import DataTable from "../../../common/DataTable/DataTable";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteConfirmationDialog from "./DeleteConfirmationDialog";
import { deleteCollectionAPI } from "../../../../api/CollectionAPI";
import { AWS_URL } from "../../../../configs/constants";

const Collections: React.FC = () => {
  const { data: collections }: { data: ICollection[] } = useSWR(
    PATH_NAME.API_ADMIN_COLLECTION,
    generalItemFetcher
  );
  const navigate = useNavigate();

  const [deleteConfirmationDialogOpen, setDeleteConfirmationDialogOpen] =
    useState(false);
  const [selectedCollectionId, setSelectedCollectionId] = useState<
    number | null
  >(null);

  const dataColumn = [
    {
      headerName: "ID",
      field: "id",
    },
    {
      field: "banner_url",
      headerName: "Banner Image",
      flex: 1,
      renderCell: (params: any) => (
        <Box sx={{ display: "flex", flexDirection: "row", gap: 1 }}>
          {params.row.banner_url && (
            <img
              src={`${AWS_URL}/${params.row.banner_url}`}
              alt={`Banner ${params.row.banner_url}`}
              style={{
                width: 50,
                height: 50,
                objectFit: "contain",
                borderRadius: 4,
              }}
            />
          )}
        </Box>
      ),
    },
    {
      headerName: "Name",
      field: "name",
      flex: 1,
    },
    {
      headerName: "Priority",
      field: "priority",
      flex: 1,
    },
    {
      headerName: "Slug",
      field: "slug",
      flex: 1,
    },
    {
      field: "actions",
      headerName: "Actions",
      renderCell: (params: any) => (
        <div>
          <IconButton
            aria-label="edit"
            size="small"
            color="primary"
            onClick={() => handleEdit(params.row.id)}
          >
            <EditIcon fontSize="small" />
          </IconButton>
          <IconButton
            aria-label="delete"
            size="small"
            color="primary"
            onClick={() => handleDeleteClick(params.row.id)}
          >
            <DeleteIcon fontSize="small" />
          </IconButton>
        </div>
      ),
    },
  ];

  const handleEdit = (id: number) => {
    navigate(`/product/collections/edit_collection/${id}`);
  };

  const handleDeleteClick = (id: number) => {
    setDeleteConfirmationDialogOpen(true);
    setSelectedCollectionId(id);
  };

  const handleDelete = async () => {
    if (selectedCollectionId) {
      await deleteCollectionAPI(selectedCollectionId.toString());
      setDeleteConfirmationDialogOpen(false);
      setSelectedCollectionId(null);
      mutate(PATH_NAME.API_ADMIN_COLLECTION);
    }
  };

  const handleCreateBanner = () => {
    navigate("/product/collections/add_collection");
  };

  return (
    <Box sx={{ padding: "20px" }}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h6" gutterBottom>
          Collections
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={handleCreateBanner}
        >
          Add
        </Button>
      </Box>
      <Divider sx={{ my: 2 }} />

      <DataTable
        columns={dataColumn}
        rows={collections || []}
        loading={false}
        getRowId={(row) => row.id}
      />
      <DeleteConfirmationDialog
        open={deleteConfirmationDialogOpen}
        handleClose={() => setDeleteConfirmationDialogOpen(false)}
        handleDelete={handleDelete}
      />
    </Box>
  );
};

export default Collections;
