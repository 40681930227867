import React from 'react';
import { useParams } from 'react-router-dom';
import { Box, Typography, CircularProgress, Divider } from '@mui/material';
import { getHQSkuDetailsBySku } from '../../../../api/GetAllSkuAPI';
import useSWR from 'swr';

const HQSku: React.FC = () => {
  const { sku } = useParams<{ sku: string }>();

  const { data: hqSku, error, isLoading } = useSWR(
    sku ? `getHQSkuDetailsBySku${sku}` : null,
    () => getHQSkuDetailsBySku(sku!)
  );

  if (isLoading) return <CircularProgress />;
  if (error) return <Typography color="error">Failed to fetch HQ SKU details.</Typography>;

  return (
    <Box>
      {hqSku && (
        <Box>
            <Typography variant="h6" gutterBottom>
              {hqSku.name}
            </Typography>
            <Divider sx={{ marginY: 1 }} />
            <Typography variant="subtitle1" gutterBottom>
              Sku: {hqSku.sku}
            </Typography>
            <Divider sx={{ marginY: 1 }} />
            <Typography variant="subtitle1" gutterBottom>
              Source ID: {hqSku.source_id}
            </Typography>
            <Divider sx={{ marginY: 1 }} />
            <Typography variant="subtitle1" gutterBottom>
              ID: {hqSku.id}
            </Typography>
            <Divider sx={{ marginY: 1 }} />
         </Box>
      )}
    </Box>
  );
};

export default HQSku;
