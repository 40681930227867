import { PATH_NAME } from "../configs/pathName";

import axiosInstance from "./axiosInstance";

export const uploadFile = async (file: File) => {
  try {
    const formData = new FormData();
    formData.append("file", file);
    const response = await axiosInstance.post(
      PATH_NAME.API_ADMIN_FILE_UPLOAD,
      formData,
      {
        headers: { "Content-Type": "multipart/form-data" },
      }
    );
    return response.data.result.file_name;
  } catch (error: any) {
    if (error.response?.status === 413) {
      throw new Error("File size is too large. Please upload a smaller file.");
    }
    throw new Error(error.message || "Error uploading file");
  }
};
