import { Box, Button, Chip, IconButton, Switch } from "@mui/material";
import { PATH_NAME } from "../../../../configs/pathName";
import { useState, useEffect } from "react";
import useSWR, { useSWRConfig } from "swr";
import { IAssetItems, IAssets } from "../../../../models/IAssets";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import DataTable from "../../../common/DataTable/DataTable";
import AnnouncementConfirmDialog from "./AnnouncementConfirmDialog";
import { enqueueSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import {
  activateAssetAPI,
  getAssetAPI,
  deleteAssetAPI,
} from "../../../../api/AssetAPI";

const placement = "announcement";

const AnouncementList = () => {
  const { mutate } = useSWRConfig();
  const navigate = useNavigate();
  const {
    data: anouncements,
    error,
    isLoading,
  } = useSWR(PATH_NAME.API_ADMIN_ASSETS, getAssetAPI);

  const [filteredAnnouncements, setFilteredAnnouncements] = useState<IAssets[]>(
    []
  );
  const [selectedId, setSelectedId] = useState<number | null>(null);
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const [selectedBanner, setSelectedBanner] = useState<IAssets | undefined>(
    undefined
  );

  const handleCreate = () => {
    navigate("add");
  };
  const handleEdit = (id: number) => {
    const banner = filteredAnnouncements.find((item) => Number(item.id) === id);
    setSelectedBanner(banner);
    navigate(`edit/${id}`);
  };

  const handleDeleteClick = (id: number) => {
    setSelectedId(id);
    setIsOpenDialog(true);
  };

  const handleClose = () => {
    setSelectedId(null);
    setSelectedBanner(undefined);
    setIsOpenDialog(false);
  };

  const handleDeleteConfirm = async () => {
    if (selectedId !== null) {
      try {
        await deleteAssetAPI(selectedId);
        await mutate(PATH_NAME.API_ADMIN_ASSETS);
        enqueueSnackbar("Announcement deleted successfully", {
          variant: "success",
        });
      } catch (error) {
        console.error("Failed to delete banner:", error);
        enqueueSnackbar("Failed to delete banner", {
          variant: "error",
        });
      }
    }
    setIsOpenDialog(false);
    setSelectedId(null);
  };

  const handleToggleActive = async (id: number) => {
    await activateAssetAPI(id);
    await mutate(PATH_NAME.API_ADMIN_ASSETS);
  };

  useEffect(() => {
    if (anouncements) {
      const filtered = anouncements
        .filter((anouncement: IAssets) => anouncement.placement === placement)
        .sort((a: IAssets, b: IAssets) => Number(a.id) - Number(b.id));
      setFilteredAnnouncements(filtered);
    }
  }, [anouncements]);

  const columns = [
    { field: "id", headerName: "ID", flex: 0 },
    {
      field: "items",
      headerName: "Announcement",
      flex: 1,
      renderCell: (params: any) => (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            overflow: "hidden",
            flexWrap: "wrap",
            alignItems: "center",
            height: "100%",
          }}
        >
          {Array.isArray(params.row.items) &&
            params.row.items.map((item: IAssetItems, index: number) => (
              <Chip label={item.text} size="small" variant="outlined" />
            ))}
        </Box>
      ),
    },
    { field: "placement", headerName: "Placement", flex: 1 },
    {
      field: "active",
      headerName: "Active",
      flex: 0,
      renderCell: (params: any) => (
        <Switch
          checked={params.row.is_active}
          onChange={() => handleToggleActive(params.row.id)}
        />
      ),
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 0,
      renderCell: (params: any) => (
        <div>
          <IconButton
            aria-label="edit"
            size="small"
            color="primary"
            onClick={() => handleEdit(params.row.id)}
          >
            <EditIcon fontSize="small" />
          </IconButton>
          <IconButton
            aria-label="delete"
            size="small"
            color="primary"
            onClick={() => handleDeleteClick(params.row.id)}
          >
            <DeleteIcon fontSize="small" />
          </IconButton>
        </div>
      ),
    },
  ];

  if (error) {
    enqueueSnackbar("Failed to fetch announcements", {
      variant: "error",
    });
  }

  return (
    <Box>
      <Box sx={{ display: "flex", justifyContent: "flex-end", mb: 2, gap: 1 }}>
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={handleCreate}
        >
          Add
        </Button>
      </Box>
      <DataTable
        rows={filteredAnnouncements || []}
        columns={columns}
        getRowId={(row) => row.id}
        loading={isLoading}
      />
      <AnnouncementConfirmDialog
        open={isOpenDialog}
        onClose={handleClose}
        onConfirm={handleDeleteConfirm}
      />
    </Box>
  );
};

export default AnouncementList;
