import { PATH_NAME } from "../configs/pathName";
import { handleError } from "../helpers/Fetchers/fetchers";
import { IPartnerLogo, IPartnerLogoCreate } from "../models/IPartnerLogo";
import axiosInstance from "./axiosInstance";

export const getPartnerLogosAPI = async (): Promise<IPartnerLogo[]> => {
  try {
    const response = await axiosInstance.get(PATH_NAME.API_ADMIN_PARTNER_LOGO);
    return response.data?.result || []; // Extract the `result` array
  } catch (error) {
    handleError(error, "Error fetching partner logos:");
    throw error;
  }
};

export const createPartnerLogoApI = async (partnerlogo: IPartnerLogoCreate) => {
    try{
        const response = await axiosInstance.post(PATH_NAME.API_ADMIN_PARTNER_LOGO, partnerlogo);
        return response.data;
    }catch (error) {
        handleError(error, 'Error creating partner logo:');
        throw error;
      }
}

export const deletePartnerLogoAPI = async (id: string) => {
    try {
      const response = await axiosInstance.delete(PATH_NAME.API_ADMIN_PARTNER_LOGO_DELETE(id));
      return response.data;
    } catch (error) {
      handleError(error, 'Error deleting partner logo:');
      throw error;
    }
  };