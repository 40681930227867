import { Box, Button, Divider, IconButton, Typography } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import useSWR, { mutate } from "swr";
import { PATH_NAME } from "../../../../configs/pathName";
import { generalItemFetcher } from "../../../../helpers/Fetchers/fetchers";
import { IBadge } from "../../../../models/IBadge";
import DataTable from "../../../common/DataTable/DataTable";
import { useNavigate } from "react-router-dom";
import { AWS_URL } from "../../../../configs/constants";
import { deleteBadgeAPI } from "../../../../api/BadgeAPI";
import { useState } from "react";
import DeleteConfirmationDialog from "./DeleteConfirmationDialog";

const BadgeList = () => {
  const dataColumn = [
    {
      headerName: "ID",
      field: "id",
    },
    {
      headerName: "Image",
      field: "filename",
      flex: 1,
      renderCell: (params: any) => (
        <img
          src={`${AWS_URL}/${params.row.filename}`}
          alt={params.row.name}
          style={{ width: "50px", height: "50px" }}
        />
      ),
    },
    {
      headerName: "Name",
      field: "name",
      flex: 1,
    },
    {
      headerName: "Actions",
      field: "actions",
      renderCell: (params: any) => (
        <div>
          <IconButton
            aria-label="edit"
            size="small"
            color="primary"
            onClick={() => handleEdit(params.row.id)}
          >
            <EditIcon fontSize="small" />
          </IconButton>
          <IconButton
            aria-label="delete"
            size="small"
            color="primary"
            onClick={() => handleDeleteClick(params.row.id)}
          >
            <DeleteIcon fontSize="small" />
          </IconButton>
        </div>
      ),
    },
  ];

  const { data: badges }: { data: IBadge[] } = useSWR(
    PATH_NAME.API_ADMIN_BADGE,
    generalItemFetcher
  );
  const navigate = useNavigate();

  const handleCreateBadge = () => {
    navigate("add");
  };

  const handleEdit = (id: number) => {
    navigate(`edit/${id}`);
  };

  const [deleteConfirmationDialogOpen, setDeleteConfirmationDialogOpen] =
    useState(false);
  const [selectedBadgeId, setSelectedBadgeId] = useState<
    number | null
  >(null);

  const handleDeleteClick = (id: number) => {
    setDeleteConfirmationDialogOpen(true);
    setSelectedBadgeId(id);
  };

  const handleDelete = async () => {
    if (selectedBadgeId) {
      await deleteBadgeAPI(selectedBadgeId.toString());
      setDeleteConfirmationDialogOpen(false);
      setSelectedBadgeId(null);
      mutate(PATH_NAME.API_ADMIN_BADGE);
    }
  };

  if (badges === undefined) {
    return (
      <Box>
        <Typography>Loading...</Typography>
      </Box>
    );
  }
  return (
    <Box>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h6" gutterBottom>
          Badges
        </Typography>
        <Button variant="contained" color="primary" onClick={handleCreateBadge}>
          Add
        </Button>
      </Box>
      <Divider sx={{ my: 2 }} />
      <DataTable
        columns={dataColumn}
        rows={badges || []}
        loading={false}
        getRowId={(row) => row.id}
      />
      <DeleteConfirmationDialog
        open={deleteConfirmationDialogOpen}
        handleClose={() => setDeleteConfirmationDialogOpen(false)}
        handleDelete={handleDelete}
      />
    </Box>
  );
};

export default BadgeList;
