import * as React from "react";
import { Theme, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Chip from "@mui/material/Chip";
import { ICollection } from "../../../../models/ICollection";
import useSWR from "swr";
import { PATH_NAME } from "../../../../configs/pathName";
import { generalItemFetcher } from "../../../../helpers/Fetchers/fetchers";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(
  collection: number,
  selectedCollections: number[],
  theme: Theme
) {
  return {
    fontWeight: selectedCollections.some((selected) => selected === collection)
      ? theme.typography.fontWeightMedium
      : theme.typography.fontWeightRegular,
  };
}

interface CollectionSelectionProps {
  onChange?: (collections: ICollection[]) => void;
  value?: number[];
}

export default function CollectionSelection({
  onChange,
  value,
}: CollectionSelectionProps) {
  const { data: collections }: { data: ICollection[] } = useSWR(
    PATH_NAME.API_ADMIN_COLLECTION,
    generalItemFetcher
  );
  const theme = useTheme();

  const [collectionIds, setCollectionIds] = React.useState<number[]>(
    value || []
  );

  if (!collections) return <div>Loading...</div>;

  const handleChange = (event: SelectChangeEvent<string[]>) => {
    const selectedIds =
      typeof event.target.value === "string"
        ? []
        : (event.target.value as string[]);
    const selectedCollections = collections.filter((c) =>
      selectedIds.includes(c.id.toString())
    );
    setCollectionIds(selectedIds.map((id) => parseInt(id)));
    onChange?.(selectedCollections);
  };

  return (
    <FormControl fullWidth size="small">
      <InputLabel id="demo-multiple-chip-label">Collections</InputLabel>
      <Select
        labelId="demo-multiple-chip-label"
        id="demo-multiple-chip"
        multiple
        value={collectionIds.map((id) => id.toString())}
        onChange={handleChange}
        input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
        renderValue={(selected: string[]) => (
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              gap: 0.5,
            }}
          >
            {collections
              .filter((c) => collectionIds.includes(c.id))
              .map((value) => (
                <Chip key={value.id} label={value.name} />
              ))}
          </Box>
        )}
        MenuProps={MenuProps}
      >
        {collections.map((collection) => (
          <MenuItem
            key={collection.id}
            value={collection.id.toString()}
            style={getStyles(collection.id, collectionIds, theme)}
          >
            {collection.id} - {collection.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
