import React, { useState } from 'react';
import { Box, Button, Divider, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Typography, Card, CardContent, Grid } from '@mui/material';
import { IContactForm } from '../../../models/IContactForm';

interface ContactFormProps {
  contactForm: IContactForm;
  onSave: (contactForm: IContactForm) => void;
  onCancel: () => void;
  isLoading: boolean;
}

const ContactForm: React.FC<ContactFormProps> = ({
  contactForm,
  onSave,
  onCancel,
  isLoading,
}) => {
  const [formData, setFormData] = useState<IContactForm>({
    ...contactForm,
    status: contactForm.status || 'pending',
  });

  const handleInputChange = (e: SelectChangeEvent<string>) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name!]: value,
    }));
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSave(formData);
  };

  return (
    <Card sx={{borderRadiu:3 }}>
      <CardContent>
        <Typography variant="h5" gutterBottom textAlign="left">
          Contact Form ID: {formData.id}
        </Typography>
        <Divider sx={{ my: 2 }} />

        <Box component="form" onSubmit={handleSubmit}>
          <Grid container spacing={5}>
            <Grid item xs={6}>
              <Typography variant="body1">
                <strong>Name:</strong> {formData.name}
              </Typography>
            </Grid>

            <Grid item xs={6}>
              <Typography variant="body1">
                <strong>Mobile No:</strong> {formData.mobile_no}
              </Typography>
            </Grid>

            <Grid item xs={6}>
              <Typography variant="body1">
                <strong>Email:</strong> {formData.email}
              </Typography>
            </Grid>

            <Grid item xs={6}>
              <Typography variant="body1">
                <strong>Remark:</strong> {formData.remarks}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="body1">
                <strong>Message:</strong> {formData.message}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="body1" gutterBottom>
                <strong>Status</strong>
              </Typography>
              <FormControl fullWidth size="small">
                <InputLabel id="status-select-label">Status</InputLabel>
                <Select
                  labelId="status-select-label"
                  name="status"
                  label="Status"
                  value={formData.status}
                  onChange={(e) => handleInputChange(e as SelectChangeEvent<string>)}
                >
                  <MenuItem value="pending">Pending</MenuItem>
                  <MenuItem value="In Progress">In Progress</MenuItem>
                  <MenuItem value="Completed">Completed</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end', gap:1 }}>
            <Button variant="outlined" color="primary" onClick={onCancel} disabled={isLoading}>
              Cancel
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={isLoading}
            >
              {isLoading ? 'Saving...' : 'Save'}
            </Button>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

export default ContactForm;
