import React, { useState, useEffect } from 'react';
import { Box, Typography, Paper, TextField, Button, IconButton, Grid, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useParams } from 'react-router-dom';
import useSWR from 'swr';
import { getSkuDetailsBySku, updateSkuDetails } from '../../../../../api/GetAllSkuAPI';
import { enqueueSnackbar } from 'notistack';
import { NutritionHeaderInfo, NutritionItem, NutritionFooter } from '../../../../../models/ISku';

const NutritionFact: React.FC = () => {
  const { sku } = useParams<{ sku: string }>();
  const { data } = useSWR(
    sku ? `getSkuDetailsBySku/${sku}` : null,
    () => (sku ? getSkuDetailsBySku(sku) : null)
  );

  const [nutritionHeader, setNutritionHeader] = useState<NutritionHeaderInfo>({
    servingsPerContainer: 0,
    servingSize: '',
    servingUnit: '',
    calories: 0
  });
  const [nutrients, setNutrients] = useState<NutritionItem[]>([]);
  const [nutritionFooter, setNutritionFooter] = useState<NutritionFooter>({ text: '' });
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (data?.result?.sku_details?.nutrition) {
      try {
        const nutritionData = data.result.sku_details.nutrition;
        if (typeof nutritionData === 'string') {
          const parsed = JSON.parse(nutritionData);
          setNutritionHeader({ ...parsed.header });
          setNutrients(parsed.nutrients || []);
          setNutritionFooter(parsed.footer || { text: '' });
        } else {
          setNutritionHeader({ ...nutritionData.header });
          setNutrients(nutritionData.nutrients || []);
          setNutritionFooter(nutritionData.footer || { text: '' });
        }
      } catch (error) {
        console.error('Error parsing nutrition data:', error);
      }
    }
  }, [data]);  

  const addMainNutrient = () => {
    setNutrients([
      ...nutrients,
      {
        id: Date.now().toString(),
        name: '',
        grams: 0,
        perserving: 0,
        subNutrients: []
      }
    ]);
  };

  const updateMainNutrient = (id: string, field: keyof NutritionItem, value: any) => {
    setNutrients(nutrients.map(ing => {
      if (ing.id === id) {
        return {
          ...ing,
          [field]: value
        };
      }
      return ing;
    }));
  };

  const removeMainNutrient = (id: string) => {
    setNutrients(nutrients.filter(ing => ing.id !== id));
  };

  const addSubNutrient = (mainId: string) => {
    setNutrients(nutrients.map(ing => {
      if (ing.id === mainId) {
        return {
          ...ing,
          subNutrients: [
            ...ing.subNutrients,
            { name: '', grams: 0 ,perserving: 0 }
          ]
        };
      }
      return ing;
    }));
  };

  const updateSubNutrient = (mainId: string, index: number, field: keyof typeof nutrients[0]['subNutrients'][0], value: any) => {
    setNutrients(nutrients.map(ing => {
      if (ing.id === mainId) {
        const newSubNutrients = [...ing.subNutrients];
        newSubNutrients[index] = {
          ...newSubNutrients[index],
          [field]: value
        };
        return {
          ...ing,
          subNutrients: newSubNutrients
        };
      }
      return ing;
    }));
  };

  const removeSubNutrient = (mainId: string, subIndex: number) => {
    setNutrients(nutrients.map(ing => {
      if (ing.id === mainId) {
        return {
          ...ing,
          subNutrients: ing.subNutrients.filter((_, index) => index !== subIndex)
        };
      }
      return ing;
    }));
  };

  const handleHeaderChange = (field: keyof NutritionHeaderInfo, value: any) => {
    setNutritionHeader(prev => ({
      ...prev,
      [field]: value
    }));
  };

  const handleFooterChange = (value: string) => {
    setNutritionFooter({ text: value });
  };

  const handleSave = async () => {
    if (!sku) {        
        return;
    }

    setIsSubmitting(true);
    try {
      // Format the nutrition data in JSONB structure
      const nutritionData = {
        header: {
          servingsPerContainer: Number(nutritionHeader.servingsPerContainer),
          servingSize: nutritionHeader.servingSize,
          servingUnit: nutritionHeader.servingUnit,
          calories: Number(nutritionHeader.calories)
        },
        nutrients: nutrients.map(nutrient => ({
          id: nutrient.id,
          name: nutrient.name,
          grams: Number(nutrient.grams),
          perserving:Number(nutrient.perserving),
          subNutrients: nutrient.subNutrients.map(sub => ({
            name: sub.name,
            grams: Number(sub.grams),
            perserving:Number(sub.perserving)
          }))
        })),
        footer: {
          text: nutritionFooter.text
        }
      };

      const updatedData = {
        ...data.result.sku_details,
        nutrition: nutritionData
      };

      // Log the formatted data for verification
      console.log('Nutrition data:', JSON.stringify(nutritionData, null, 2));
      
      const response = await updateSkuDetails(sku, updatedData);
      console.log('Update response:', response);

      enqueueSnackbar("Nutrition facts updated successfully!", { variant: "success" });
    } catch (error) {
      console.error('Error saving nutrition facts:', error);
      enqueueSnackbar("Failed to update nutrition facts.", { variant: "error" });
    } finally {
      setIsSubmitting(false);
    }
  };

  const renderPreview = () => {
    return (
      <Paper 
      sx={{ 
        maxWidth: 400, 
        margin: '0 auto',
        border: '2px solid black',
        borderRadius: 2,
        mt: 4,
        overflow: 'hidden'
      }}
    >
      {/* Header */}
      <Box sx={{ 
        bgcolor: 'black',
        color: 'white',
        p: 1,
        textAlign: 'center'
      }}>
        <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
          Nutritional Information
        </Typography>
      </Box>

      {/* Serving Information */}
      <Box sx={{ p: 1.5, borderBottom: '1px solid #ddd' }}>
        <Typography sx={{ fontSize: '14px' }}>
          Serving size: {nutritionHeader.servingSize}{nutritionHeader.servingUnit}
        </Typography>
        <Typography sx={{ fontSize: '14px' }}>
          Servings per package: {nutritionHeader.servingsPerContainer}
        </Typography>
      </Box>

      {/* Column Headers */}
      <Box sx={{ 
        display: 'grid',
        gridTemplateColumns: '1fr 100px 100px',
        borderBottom: '2px solid black',
        borderTop: '2px solid black',
        p: 1
      }}>
        <Box />
        <Typography sx={{ 
          fontWeight: 'bold',
          fontSize: '14px',
          textAlign: 'center'
        }}>
          Per Serving
        </Typography>
        <Typography sx={{ 
          fontWeight: 'bold',
          fontSize: '14px',
          textAlign: 'center'
        }}>
          Per 100g
        </Typography>
      </Box>

      {/* Nutrients */}
      {nutrients.map((nutrient) => (
        <Box key={nutrient.id}>
          <Box sx={{ 
            display: 'grid',
            gridTemplateColumns: '1fr 100px 100px',
            borderBottom: '1px solid #ddd',
            p: 1
          }}>
            <Typography sx={{ fontSize: '14px' }}>
              {nutrient.name}
            </Typography>
            <Typography sx={{ 
              fontSize: '14px',
              textAlign: 'center'
            }}>
              {nutrient.perserving}
            </Typography>
            <Typography sx={{ 
              fontSize: '14px',
              textAlign: 'center'
            }}>
              {nutrient.grams}
            </Typography>
          </Box>

          {/* Sub-nutrients */}
          {nutrient.subNutrients?.map((subNutrient, index) => (
            <Box 
              key={index}
              sx={{ 
                display: 'grid',
                gridTemplateColumns: '1fr 100px 100px',
                borderBottom: '1px solid #ddd',
                p: 1
              }}
            >
              <Typography sx={{ 
                fontSize: '14px',
                pl: 2
              }}>
                - {subNutrient.name}
              </Typography>
              <Typography sx={{ 
                fontSize: '14px',
                textAlign: 'center'
              }}>
                {subNutrient.perserving}
              </Typography>
              <Typography sx={{ 
                fontSize: '14px',
                textAlign: 'center'
              }}>
                {subNutrient.grams}
              </Typography>
            </Box>
          ))}
        </Box>
      ))}
    </Paper>
    );
  };

  const renderNutritionHeader = () => {
    return (
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField
            fullWidth
            label="Serving Size"
            value={nutritionHeader.servingSize}
            onChange={(e) => handleHeaderChange('servingSize', e.target.value)}
            size="small"
            placeholder=''
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            label="Serving Unit"
            value={nutritionHeader.servingUnit}
            onChange={(e) => handleHeaderChange('servingUnit', e.target.value)}
            size="small"
            placeholder=''
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            label="Servings Per Package"
            placeholder=''
            type="number"
            value={nutritionHeader.servingsPerContainer}
            onChange={(e) => handleHeaderChange('servingsPerContainer', Number(e.target.value))}
            size="small"
          />
        </Grid>
        {/* <Grid item xs={6}>
          <TextField
            fullWidth
            label="Calories"
            type="number"
            value={nutritionHeader.calories}
            onChange={(e) => handleHeaderChange('calories', Number(e.target.value))}
            size="small"
          />
        </Grid> */}
      </Grid>
    );
  };

  return (
    <Box>
      <Grid container spacing={4}>
        {/* Left Side - Accordions */}
        <Grid item xs={12} md={6}>
          {/* Nutrition Header Section */}
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              sx={{ 
                borderBottom: '1px solid #ddd',
                borderTop: '1px solid #ddd',
              }}
            >
              <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                NUTRITION FACTS HEADER
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Paper sx={{ p: 3 }}>
                {renderNutritionHeader()}
              </Paper>
            </AccordionDetails>
          </Accordion>

          {/* Nutrients Section */}
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              sx={{ 
                borderBottom: '1px solid #ddd',
              }}
            >
              <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                NUTRIENTS
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Paper sx={{ p: 3 }}>
                {nutrients.map((nutrient) => (
                  <Box key={nutrient.id} sx={{ mb: 3 }}>
                    <Grid container spacing={2}>
                      <Grid item xs={4}>
                        <TextField
                          fullWidth
                          label="Nutrient Name"
                          value={nutrient.name}
                          onChange={(e) => updateMainNutrient(nutrient.id, 'name', e.target.value)}
                          size="small"
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <TextField
                          fullWidth
                          label="Per serving"
                          type="number"
                          value={nutrient.perserving || 0}
                          onChange={(e) => updateMainNutrient(nutrient.id, 'perserving', Number(e.target.value))}
                          size="small"
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <TextField
                          fullWidth
                          label="Per 100g"
                          type="number"
                          value={nutrient.grams || 0}
                          onChange={(e) => updateMainNutrient(nutrient.id, 'grams', e.target.value ? Number(e.target.value) : undefined)}
                          size="small"
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <IconButton 
                          onClick={() => removeMainNutrient(nutrient.id)}
                          size="small"
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Grid>
                    </Grid>

                    {/* Sub-nutrients */}
                    {nutrient.subNutrients.map((subNutrient, index) => (
                      <Box key={index} sx={{ ml: 4, mt: 2 }}>
                        <Grid container spacing={2}>
                          <Grid item xs={4}>
                            <TextField
                              fullWidth
                              label="Sub-nutrient Name"
                              value={subNutrient.name}
                              onChange={(e) => updateSubNutrient(nutrient.id, index, 'name', e.target.value)}
                              size="small"
                            />
                          </Grid>
                          <Grid item xs={3}>
                            <TextField
                              fullWidth
                              label="Per Serving"
                              type="number"
                              value={subNutrient.perserving || 0}
                              onChange={(e) => updateSubNutrient(nutrient.id, index, 'perserving', Number(e.target.value))}
                              size="small"
                            />
                          </Grid>
                          <Grid item xs={3}>
                            <TextField
                              fullWidth
                              label="Per 100g"
                              type="number"
                              value={subNutrient.grams || 0}
                              onChange={(e) => updateSubNutrient(nutrient.id, index, 'grams', e.target.value ? Number(e.target.value) : undefined)}
                              size="small"
                            />
                          </Grid>
                          <Grid item xs={2}>
                            <IconButton 
                              onClick={() => removeSubNutrient(nutrient.id, index)}
                              size="small"
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Grid>
                        </Grid>
                      </Box>
                    ))}

                    <Button
                      startIcon={<AddIcon />}
                      onClick={() => addSubNutrient(nutrient.id)}
                      sx={{ ml: 4, mt: 1 }}
                      size="small"
                    >
                      Add Sub-nutrient
                    </Button>
                  </Box>
                ))}
                <Button
                  variant="contained"
                  startIcon={<AddIcon />}
                  onClick={addMainNutrient}
                  sx={{ mt: 2 }}
                >
                  Add Main Nutrient
                </Button>
              </Paper>
            </AccordionDetails>
          </Accordion>

          {/* Footer Section */}
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              sx={{ 
                borderBottom: '1px solid #ddd',
              }}
            >
              <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                NUTRITION FACTS FOOTER
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Paper sx={{ p: 3 }}>
                <TextField
                  fullWidth
                  label="Footer Text"
                  multiline
                  rows={4}
                  value={nutritionFooter.text}
                  onChange={(e) => handleFooterChange(e.target.value)}
                  size="small"
                />
              </Paper>
            </AccordionDetails>
          </Accordion>

          {/* Save Button */}
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2, gap: 1 }}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSave}
              disabled={isSubmitting}
            >
              {isSubmitting ? "Saving..." : "Save Changes"}
            </Button>
          </Box>
        </Grid>

        {/* Right Side - Preview */}
        <Grid item xs={12} md={6}>
          {renderPreview()}
        </Grid>
      </Grid>
    </Box>
  );
};

export default NutritionFact;
