import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Typography,
  IconButton,
  Stack,
  CircularProgress,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useNavigate, useParams } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { IAssetItems, IAssets } from "../../../../models/IAssets";
import { getAssetByIdAPI, updateAssetAPI } from "../../../../api/AssetAPI";
import BannerForm from "./BannerForm";

const generateId = () => Math.random().toString(36).substr(2, 9);

const initialBannerItems: IAssetItems = {
  id: generateId(),
  asset: "",
  text: "",
  text_color: "#000000",
  btn_text: "",
  btn_url: "/",
  position: "left",
};

const BannerEdit: React.FC = () => {
  const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const fileInputRef = useRef<HTMLInputElement>(null);

  const [banner, setBanner] = useState<IAssets | null>(null);
  const [bannerItem, setBannerItem] = useState<IAssetItems[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    let isMounted = true;

    if (id) {
      setIsLoading(true);
      getAssetByIdAPI(id)
        .then((data) => {
          if (isMounted) {
            setBanner(data);
            const itemsWithIds = data.items?.map((item: IAssetItems) => ({
              ...item,
              id: item.id || generateId(),
            }));
            setBannerItem(itemsWithIds || []);
          }
        })
        .catch(() => {
          if (isMounted) {
            enqueueSnackbar("Failed to fetch banner data.", {
              variant: "error",
            });
          }
        })
        .finally(() => {
          if (isMounted) {
            setIsLoading(false);
          }
        });
    }

    return () => {
      isMounted = false;
    };
  }, [id, enqueueSnackbar]);

  const handleSubmit = async () => {
    if (!id || bannerItem.length === 0) {
      enqueueSnackbar("Please provide at least one banner item.", {
        variant: "warning",
      });
      return;
    }

    try {
      setIsSubmitting(true);
      console.log("Submitting banner items:", bannerItem);

      const payload = {
        placement: "banner",
        items: bannerItem,
        is_active: banner?.is_active,
      };

      console.log("Payload being sent:", payload);
      await updateAssetAPI(id, payload);

      enqueueSnackbar("Banner updated successfully!", { variant: "success" });
      navigate(-1);
    } catch (error) {
      console.error("Error updating banner:", error);
      enqueueSnackbar("Failed to update banner.", { variant: "error" });
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleRemoveItem = (idToRemove: string) => {
    if (bannerItem.length <= 1) {
      enqueueSnackbar("At least one banner item is required.", {
        variant: "warning",
      });
      return;
    }
    setBannerItem((prevItems) =>
      prevItems.filter((item) => item.id !== idToRemove)
    );
  };

  const handleChangeBannerItem = (updatedItem: IAssetItems, id: string) => {
    setBannerItem((prevItems) =>
      prevItems.map((item) => (item.id === id ? updatedItem : item))
    );
  };

  const addBannerItems = () => {
    setBannerItem((prevItems) => [
      ...prevItems,
      { ...initialBannerItems, id: generateId() },
    ]);
  };

  if (isLoading) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", p: 3 }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ maxWidth: 1200, margin: "0 auto" }}>
      <Stack direction="row" alignItems="center" spacing={2} mb={4}>
        <IconButton onClick={() => navigate(-1)}>
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="h5">Edit Banner</Typography>
        <Typography sx={{fontSize:'12px', color:'red'}}>Size: 1200px * 628px / jpg, jpeg</Typography>
      </Stack>

      {bannerItem.map((item) => (
        <BannerForm
          key={item.id}
          bannerItems={item}
          updateBannerItems={handleChangeBannerItem}
          bannerId={item.id || ""}
          removeBannerItem={handleRemoveItem}
        />
      ))}
      <Button variant="outlined" onClick={addBannerItems} sx={{ mt: 2 }}>
        Add Banner
      </Button>

      <Stack direction="row" spacing={2} justifyContent="flex-end">
        <Button variant="outlined" onClick={() => navigate(-1)}>
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={handleSubmit}
          disabled={isLoading || bannerItem.length === 0}
        >
          {isLoading ? <CircularProgress size={24} /> : "Update Banner"}
        </Button>
      </Stack>
    </Box>
  );
};

export default BannerEdit;
