import { useEffect, useState } from "react";
import { useAppDispatch } from "../../../redux/features/redux-hooks";
import { resetPassword } from "../../../redux/features/Auth/AuthThunk";
import { Box, Container, Typography, Button, TextField, Link as MuiLink, IconButton, InputAdornment } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from '../../../components/common/SnackBar/snackBar';
import { AxiosError } from 'axios';
import { MuiOtpInput } from "mui-one-time-password-input";
import { sendOtp } from "../../../api/AuthAPI";
import { PATH_NAME } from "../../../configs/pathName";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const ResetPasswordOTPPage = () => {
    const { state } = useLocation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [newPassword, setNewPassword] = useState("");
    const [otp, setOtp] = useState("");
    const [showPassword, setShowPassword] = useState(false);

    const [timer, setTimer] = useState(60);
    const [canResend, setCanResend] = useState(true);
    const [countdownStarted, setCountdownStarted] = useState(false);

    useEffect(() => {
        if (countdownStarted && timer > 0) {
            const countdown = setTimeout(() => setTimer(timer - 1), 1000);
            return () => clearTimeout(countdown);
        } else if (timer === 0) {
            setCanResend(true);
            setCountdownStarted(false);
        }
    }, [timer, countdownStarted]);

    const phone = state?.phone || "";

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
      };

    const handleResetPassword = async () => {
        if (newPassword && otp) {
            try {
                await dispatch(resetPassword({ new_password: newPassword, otp, full_mobile: phone })).unwrap();
                toast.success("Password reset successfully!");
                navigate(PATH_NAME.LOGIN);
            } catch (error) {
                if (error instanceof AxiosError) {
                    const status = error.response?.status;
                    if (status === 400) {
                        toast.error("Invalid OTP or phone number.");
                    } else if (status === 404) {
                        toast.error("User not found.");
                    } else {
                        toast.error("Failed to reset password.");
                    }
                } else {
                    toast.error("Incorrect OTP.");
                }
            }
        } else {
            toast.error("Please enter both OTP and a new password.");
        }
    };

    const handleResendOtp = async () => {
        try {
            await sendOtp(phone);
            setTimer(30);
            setCanResend(false);
            setCountdownStarted(true);
            toast.success("OTP resent successfully!");
        } catch (error) {
            toast.error("Failed to resend OTP. Please try again.");
        }
    };

    return (
        <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
            <Container maxWidth="xs">
                <Typography align="center">
                    Reset Your Password
                </Typography>
                <Typography align="center">
                    Enter the OTP and your new password.
                </Typography>
                <Box>
                <MuiOtpInput
                    length={6}
                    value={otp}
                    onChange={setOtp}
                    sx={{mt:2}}
                    TextFieldsProps={{
                        inputProps: {
                        inputMode: 'numeric',
                        pattern: '[0-9]*',
                        
                        },
                        placeholder: '',
                        
                    }}
                    />

                    <TextField
                        fullWidth
                        variant="outlined"
                        label="New Password"
                        type={showPassword ? "text" : "password"}
                        value={newPassword}
                        sx={{mt:2}}
                        onChange={(e) => setNewPassword(e.target.value)}
                        InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowPassword}
                                  edge="end"
                                >
                                  {showPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                              </InputAdornment>
                            ),
                            
                          }}
                        />
                    <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        onClick={handleResetPassword}
                        sx={{ mt: 3, mb: 2, backgroundColor: '#ff5e2c', '&:hover': { backgroundColor: '#ff5e2c' } }}
                        >
                        Reset Password
                    </Button>
                    <Typography align="center">
                            Didn’t receive the OTP?{' '}
                            {canResend ? (
                                <MuiLink component="button" onClick={handleResendOtp}>
                                    Resend OTP
                                </MuiLink>
                                
                            ) : (
                                <span>Resend OTP in {timer} seconds</span>
                            )}
                        </Typography>
                </Box>
            </Container>
        </Box>
    );
};

export default ResetPasswordOTPPage;
