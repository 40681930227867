import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

interface AnnouncementConfirmDialogProps {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

const AnnouncementConfirmDialog = ({
  open,
  onClose,
  onConfirm,
}: AnnouncementConfirmDialogProps) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Delete Announcement</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to delete this announcement?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={onConfirm} color="error" autoFocus>
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AnnouncementConfirmDialog;
