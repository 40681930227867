import React from "react";
import { CircularProgress, Typography, Backdrop } from "@mui/material";

interface LoadingOverlayProps {
  open: boolean;
  message?: string;
}

const LoadingOverlay: React.FC<LoadingOverlayProps> = ({ open, message }) => {
  return (
    <Backdrop
      sx={{
        color: "#fff",
        zIndex: (theme) => theme.zIndex.modal + 1,
        display: "flex",
        flexDirection: "column",
        gap: 2,
      }}
      open={open}
    >
      <CircularProgress color="inherit" />
      {message && <Typography>{message}</Typography>}
    </Backdrop>
  );
};

export default LoadingOverlay;
