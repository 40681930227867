import useSWR from "swr";
import { getWalletPaymentByUserIdAPI } from "../../../../api/StoreCreditAPI";
import { Box, Container, Divider, Typography } from "@mui/material";
import { orderDetailStyles } from "../../../styles/PaymentList";
import { IStoreCreditTransaction } from "../../../../models/IStoreCredit";

const StoreCreditTransactionPage = ({ userId }: { userId: number }) => {
  const { data: transactions, error } = useSWR<IStoreCreditTransaction[]>(
    ["getStoreCreditTransactionByUserId", userId],
    () => getWalletPaymentByUserIdAPI(userId)
  );

  const validTransactions = Array.isArray(transactions) ? transactions : [];

  const formatDate = (dateString: string | undefined) => {
    if (!dateString) return 'N/A';
    const date = new Date(dateString);
    return `${date.toLocaleDateString('en-SG', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    })}`;
  };

  return (
    <Container sx={orderDetailStyles.container}>
      <Typography variant="h6" sx={orderDetailStyles.title}>
        Wallet Payment
      </Typography>

      {error && (
        <Typography color="error">
          Error: {error.message || "Failed to fetch transactions!"}
        </Typography>
      )}

      {validTransactions.length === 0 ? (
        <Typography>No transactions found.</Typography>
      ) : (
        // Transactions List
        validTransactions.map((transaction) => (
          <Box key={transaction.id} sx={orderDetailStyles.summaryBox}>
            <Box sx={orderDetailStyles.headerRow}>
              <Typography sx={orderDetailStyles.headerText}>Transaction ID</Typography>
              <Typography sx={orderDetailStyles.headerText}>{transaction.id}</Typography>
            </Box>
            <Divider sx={orderDetailStyles.divider} />
            <Box sx={orderDetailStyles.headerRow}>
              <Typography sx={orderDetailStyles.headerText}>Description</Typography>
              <Typography sx={orderDetailStyles.headerText}>{transaction.description}</Typography>
            </Box>
            <Divider sx={orderDetailStyles.divider} />
            <Box sx={orderDetailStyles.headerRow}>
              <Typography sx={orderDetailStyles.headerText}>Type</Typography>
              <Typography sx={orderDetailStyles.headerText}>{transaction.type}</Typography>
            </Box>
            <Divider sx={orderDetailStyles.divider} />
            <Box sx={orderDetailStyles.headerRow}>
              <Typography sx={orderDetailStyles.headerText}>Created Date</Typography>
              <Typography sx={orderDetailStyles.headerText}>{formatDate(transaction.created_at)}</Typography>
            </Box>
            <Divider sx={orderDetailStyles.divider} />
            <Box sx={orderDetailStyles.subtotalRow}>
              <Typography sx={orderDetailStyles.headerText}>Amount</Typography>
              <Typography sx={orderDetailStyles.headerText}>{formatCurrency(transaction.amount)}</Typography>
            </Box>
          </Box>
        ))
      )}
    </Container>
  );
};

const formatCurrency = (amount: number) => {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(amount);
};

export default StoreCreditTransactionPage;
