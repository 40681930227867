import {
  Box,
  Button,
  FormControl,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { ChangeEvent, useState } from "react";
import { useNavigate } from "react-router-dom";
import { enqueueSnackbar } from "notistack";
import { AWS_URL } from "../../../configs/constants";
import { createPartnerLogoApI } from "../../../api/PartnerLogoAPI";
import { IPartnerLogoCreate } from "../../../models/IPartnerLogo";
import ImagePreview from "../ProductManagement/Sku/ImagePreview";

const defaultFormData: IPartnerLogoCreate = {
  name: "",
  logo: "",
  priority:0,
};

const AddPartnerLogo = () => {
  const [formData, setFormData] = useState<IPartnerLogoCreate>(defaultFormData);

  const navigate = useNavigate();

  const handleImageUrlChange = (url: string) => {
    setFormData((prev) => ({ ...prev, logo: url }));
  };

  const handleNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFormData((prev) => ({ ...prev, name: e.target.value }));
  };

  const handlePriorityChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFormData((prev) => ({ ...prev, priority: Number(e.target.value) }));
  };  

  const handleCancel = () => {
    navigate("/Content/HomePageContent/partners/");
  };

  const handleSubmit = async () => {
    try {
      await createPartnerLogoApI(formData);
      navigate("/Content/HomePageContent/partners/");
      enqueueSnackbar("Partner Logo created successfully!", {
        variant: "success",
      });
      setFormData(defaultFormData);
    } catch (error) {
      enqueueSnackbar("Failed to create Partner Logo.", { variant: "error" });
    }
  };

  return (
    <Box>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h6" gutterBottom>
          Create Partner Logo
        </Typography>
      </Box>

      <Grid container spacing={2}>
        <Grid item container xs={12}>
          <Grid item xs={2} sx={{ placeContent: "center" }}>
            <Typography variant="subtitle2" fontWeight="bold">
              Partner Logo
            </Typography>
            <Typography sx={{ fontSize: "12px", color: "red" }}>
              550px * 440px
            </Typography>
          </Grid>
          <Grid item xs={10} sx={{ placeContent: "center" }}>
            <FormControl fullWidth>
              <ImagePreview
                onImageUrlChange={handleImageUrlChange}
                value={formData?.logo ? `${AWS_URL}/${formData.logo}` : ""}
                />
            </FormControl>
          </Grid>
        </Grid>

        <Grid item container xs={12}>
          <Grid item xs={2} sx={{ placeContent: "center" }}>
            <Typography variant="subtitle2" fontWeight="bold">
              Partner Name
            </Typography>
          </Grid>
          <Grid item xs={10} sx={{ placeContent: "center" }}>
            <FormControl fullWidth>
              <TextField
                label="Name"
                name="name"
                value={formData.name}
                size="small"
                fullWidth
                onChange={handleNameChange}
              />
            </FormControl>
          </Grid>
        </Grid>
        <Grid item container xs={12}>
          <Grid item xs={2} sx={{ placeContent: "center" }}>
            <Typography variant="subtitle2" fontWeight="bold">
              Priority
            </Typography>
          </Grid>
          <Grid item xs={10} sx={{ placeContent: "center" }}>
            <FormControl fullWidth>
              <TextField
                label="Priority"
                name="priority"
                value={formData.priority}
                size="small"
                type="number"
                fullWidth
                onChange={handlePriorityChange}
              />
            </FormControl>
          </Grid>
        </Grid>

        <Stack
          direction="row"
          justifyContent="flex-end"
          gap={2}
          mt={4}
          sx={{ width: "100%" }}
        >
          <Button variant="contained" color="inherit" onClick={handleCancel}>
            Cancel
          </Button>
          <Button variant="contained" color="primary" onClick={handleSubmit}>
            Save
          </Button>
        </Stack>
      </Grid>
    </Box>
  );
};

export default AddPartnerLogo;
