import * as React from "react";
import { Theme, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Chip from "@mui/material/Chip";
import useSWR from "swr";
import { PATH_NAME } from "../../../../configs/pathName";
import { generalItemFetcher } from "../../../../helpers/Fetchers/fetchers";
import { IBadge } from "../../../../models/IBadge";
import { Avatar } from "@mui/material";
import { AWS_URL } from "../../../../configs/constants";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(
    badge: number,
  selectedBadges: number[],
  theme: Theme
) {
  return {
    fontWeight: selectedBadges.some((selected) => selected === badge)
      ? theme.typography.fontWeightMedium
      : theme.typography.fontWeightRegular,
  };
}

interface BadgeSelectionProps {
  onChange?: (badges: IBadge[]) => void;
  value?: number[];
}

export default function BadgeSelection({
  onChange,
  value,
}: BadgeSelectionProps) {
  const { data: badges }: { data: IBadge[] } = useSWR(
    PATH_NAME.API_ADMIN_BADGE,
    generalItemFetcher
  );
  const theme = useTheme();

  const [badgeIds, setBadgeIds] = React.useState<number[]>(
    value || []
  );

  console.log("badgeIds", badgeIds)
  if (!badges) return <div>Loading...</div>;

  const handleChange = (event: SelectChangeEvent<string[]>) => {
    const selectedIds =
      typeof event.target.value === "string"
        ? []
        : (event.target.value as string[]);
    const selectedBadges = badges.filter((c) =>
      selectedIds.includes(c.id.toString())
    );
    setBadgeIds(selectedIds.map((id) => parseInt(id)));
    onChange?.(selectedBadges);
  };

  return (
    <FormControl fullWidth size="small">
      <InputLabel id="demo-multiple-chip-label">Badges</InputLabel>
      <Select
        labelId="demo-multiple-chip-label"
        id="demo-multiple-chip"
        multiple
        value={badgeIds.map((id) => id.toString())}
        onChange={handleChange}
        input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
        renderValue={(selected: string[]) => (
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              gap: 0.5,
            }}
          >
            {badges
              .filter((badge) => selected.includes(badge.id.toString()))
              .map((value) => (
                <Chip key={value.id} 
                avatar={<Avatar alt={value.name} src={`${AWS_URL}/${value.filename}`} />}
                label={value.name} />
              ))}
          </Box>
        )}
        MenuProps={MenuProps}
      >
        {badges.map((badge) => (
          <MenuItem
            key={badge.id}
            value={badge.id.toString()}
            style={getStyles(badge.id, badgeIds, theme)}
          >
            <Avatar alt={badge.name} src={`${AWS_URL}/${badge.filename}`} /> {badge.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
