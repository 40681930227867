import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import DataTable from '../../../common/DataTable/DataTable';
import { Box, Button, IconButton } from '@mui/material';
import useSWR from 'swr';
import { deleteSkuDetails, getAllSkus } from '../../../../api/GetAllSkuAPI';
import DeleteIcon from '@mui/icons-material/Delete';
import { useSnackbar } from 'notistack';
import EditIcon from '@mui/icons-material/Edit';
import { AWS_URL } from '../../../../configs/constants';
import { PATH_NAME } from '../../../../configs/pathName';
import { generalItemFetcher } from '../../../../helpers/Fetchers/fetchers';
import { ICollection } from '../../../../models/ICollection';

const SkuListPage: React.FC = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { data: skus, error, isLoading, mutate } = useSWR('getAllSkus', getAllSkus);
  const { data: collections } = useSWR(PATH_NAME.API_ADMIN_COLLECTION, generalItemFetcher);
  const [deleting, setDeleting] = useState(false);

  const handleCreate = (sku: string | null = null) => {
    navigate(`/product/add_skus_details/${sku}`, { state: { sku } });
  };

  const handleEdit = (sku: string) => {
    navigate(`/product/skus_details/${sku}`);
  };

  const handleDelete = async (sku: string) => {
    if (!window.confirm('Are you sure you want to delete this SKU?')) return;

    setDeleting(true);
    try {
      await deleteSkuDetails(sku);
      enqueueSnackbar('SKU deleted successfully', { variant: 'success' });
      mutate();
    } catch (err: any) {
      enqueueSnackbar(err, { variant: 'error' });
    } finally {
      setDeleting(false);
    }
  };

  const columns = [
    { field: 'sku', headerName: 'SKU', flex: 0 },
    {
      field: 'sku_details.image_url',
      headerName: 'Images',
      flex: 0,
      renderCell: (params: any) => (
        <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1 }}>
          {params.row.sku_details && params.row.sku_details.image_url && (
            <img
              src={`${AWS_URL}/${params.row.sku_details.image_url}`}
              alt={`SKU ${params.row.sku}`}
              style={{ width: 50, height: 50, objectFit: 'contain', borderRadius: 4 }}
            />
          )}
        </Box>
      ),
    },
    { field: 'name', headerName: 'Name', flex: 2 },
    {
      field: 'sku_details.collections',
      headerName: 'Collections',
      flex: 2,
      renderCell: (params: any) => (
        <div>
          {Array.isArray(params.row.sku_details?.collections) &&
          params.row.sku_details.collections.length > 0 ? (
            params.row.sku_details.collections
              .map((collectionId: number) => {
                const collection = collections?.find(
                  (col: ICollection) => col.id === collectionId
                );
                return collection ? collection.name : null;
              })
              .filter((name: string | null): name is string => name !== null)
              .join(', ')
          ) : (
            ''
          )}
        </div>
      ),
    },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 0,
      renderCell: (params: any) => (
        <div>
          {params.row.short_name && (
            <IconButton
              aria-label="view"
              size="small"
              color="primary"
              onClick={() => handleEdit(params.row.sku)}
            >
              <EditIcon fontSize="small" />
            </IconButton>
          )}
          {params.row.short_name && (
            <IconButton
              aria-label="delete"
              size="small"
              color="primary"
              onClick={() => handleDelete(params.row.sku)}
              disabled={deleting}
            >
              <DeleteIcon fontSize="small" />
            </IconButton>
          )}
        </div>
      ),
    },
    {
      field: 'status',
      headerName: 'Sku Details',
      flex: 1,
      renderCell: (params: any) => (
        <div>
          {params.row.status ? (
            '✅'
          ) : (
            <Button
              variant="outlined"
              color="primary"
              size="small"
              onClick={() => handleCreate(params.row.sku)}
            >
              Add Sku Details
            </Button>
          )}
        </div>
      ),
    },
  ];

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error loading SKUs</div>;

  const transformedSkus =
    skus?.map((item: any) => ({
      ...item,
      price: item.sku_details?.price,
      description: item.sku_details?.description,
      weight: item.sku_details?.weight,
      size: item.sku_details?.size,
      short_name: item.sku_details?.short_name,
      status: !!item.sku_details,
    })) || [];

  return (
    <div>
      <DataTable
        rows={transformedSkus}
        columns={columns}
        getRowId={(row) => row.id}
        loading={isLoading}
      />
    </div>
  );
};

export default SkuListPage;
