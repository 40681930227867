import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { IButtonLink } from '../../../models/IButtonLink';
import ButtonLinkForm from '../../common/ButtonLinkForm/ButtonLinkForm';
import { toast } from '../../common/SnackBar/snackBar';
import { getButtonLinkByIdAPI, updateButtonLinkAPI } from '../../../api/ButtonLink';
import useSWR from 'swr';

const EditButtonLinkPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [buttonLinkForm, setButtonLinkForm] = useState<IButtonLink | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const { data: buttonLinkData, error } = useSWR(
    id ? `getButtonLinkByIdAPI/${id}` : null,
    () => getButtonLinkByIdAPI(id!).then((response) => {
      return response;
    })
  );

  
  useEffect(() => {
    if (buttonLinkData) {
      setButtonLinkForm(buttonLinkData);
    }
  }, [buttonLinkData]);

  const handleSave = async (buttonLink: IButtonLink) => {
    if (!id) return;

    setIsLoading(true);
    try {
      await updateButtonLinkAPI(buttonLink);
      toast.success('Button link updated successfully');
      navigate('/Content/HomePageContent/button_link');
    } catch (error) {
      toast.error('Error updating button link');
    } finally {
      setIsLoading(false);
    }
  };

  const handleCancel = () => {
    navigate('/Content/HomePageContent/button_link');
  };

  if (error) return <div>Error loading button link data</div>;
  if (!buttonLinkForm) return <div>Loading...</div>;

  return (
    <div>
      <h2>Edit Button Link</h2>
      <ButtonLinkForm
        buttonLinkForm={buttonLinkForm}
        onSave={handleSave}
        onCancel={handleCancel}
        isLoading={isLoading}
      />
    </div>
  );
};

export default EditButtonLinkPage;
