import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { IButtonLink } from '../../../models/IButtonLink';
import ButtonLinkForm from '../../common/ButtonLinkForm/ButtonLinkForm';
import { toast } from '../../common/SnackBar/snackBar';
import { createNewButtonLinkAPI } from '../../../api/ButtonLink';

const defaultButtonLink: IButtonLink = {
  id: 0,
  name: '',
  url: '',
  created_at: '',
  updated_at: '',
  deleted_at: null,
  created_by: 0,
  updated_by: 0,
  deleted_by: null,
};

const CreateButtonLinkPage: React.FC = () => {
  const [buttonLinkForm] = useState<IButtonLink>(defaultButtonLink);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleSave = async (buttonLink: IButtonLink) => {
    setIsLoading(true);
    try {
      await createNewButtonLinkAPI(buttonLink);
      toast.success('Button link created successfully');
      navigate('/Content/HomePageContent/button_link');
    } catch (error) {
      toast.error('Error creating button link');
    } finally {
      setIsLoading(false);
    }
  };

  const handleCancel = () => {
    navigate('/Content/HomePageContent/button_link/');
  };

  return (
    <div>
      <h2>Create Button Link</h2>
      <ButtonLinkForm
        buttonLinkForm={buttonLinkForm}
        onSave={handleSave}
        onCancel={handleCancel}
        isLoading={isLoading}
      />
    </div>
  );
};

export default CreateButtonLinkPage;
