import React, { useState } from 'react';
import { Box, Container, CssBaseline, Typography, Button } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';
import { toast } from '../../common/SnackBar/snackBar';
import { sendOtp } from '../../../api/AuthAPI';
import { PATH_NAME } from '../../../configs/pathName';

const ForgetPasswordPage: React.FC = () => {
    const navigate = useNavigate();
    const [phone, setPhone] = useState("");

    const handleSendOtp = async () => {
        const fullMobile = `+${phone.match(/\d+/)?.[0]}`;
    
        if (fullMobile) {
            try {
                const response = await sendOtp(fullMobile);
                if (response.success) {
                    toast.success("OTP sent successfully!");
                    navigate(PATH_NAME.RESET_PASSWORD_VERIFY, { state: { phone: fullMobile } });
                } else {
                    toast.error("Failed to send OTP.");
                }
            } catch (error) {
                toast.error("An unknown error occurred.");
            }
        } else {
            toast.error("Please enter a valid phone number.");
        }
    };
    
    return (
        <>
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
                <Container maxWidth="xs">
                    <CssBaseline />
                    <Typography variant="h5" component="h1" gutterBottom align="left">
                        Reset Your Password
                    </Typography>
                    <Typography variant="body1" gutterBottom align="left">
                        Enter your phone number to receive an OTP for resetting your password.
                    </Typography>
                    <Box>
                        <Box>
                            <PhoneInput
                                country={'sg'}
                                value={phone}
                                onChange={setPhone}
                                inputProps={{
                                    name: 'phone',
                                    required: true,
                                    autoFocus: true,
                                }}
                                containerStyle={{ width: "100%", marginTop: "16px" }}
                                inputStyle={{ width: "100%" }}
                              
                                  />
                            <Button
                                fullWidth
                                variant="contained"
                                onClick={handleSendOtp}
                                sx={{ mt: 3, mb: 2, backgroundColor: '#ff5e2c', '&:hover': { backgroundColor: '#ff5e2c' } }}
                                >
                                Send OTP
                            </Button>
                            <Typography variant="body2" align="center">
                                Remembered your password?{' '}
                                <Link to={PATH_NAME.LOGIN}>
                                    Log in
                                </Link>
                            </Typography>
                        </Box>
                    </Box>
                </Container>
            </Box>
        </>
    );
};

export default ForgetPasswordPage;