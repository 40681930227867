import { useEffect, useState } from 'react';
import { ButtonLinkAPI } from '../api/ButtonLink';
import { PATH_NAME } from './pathName';

export const BACKEND_BASE_URL = process.env.REACT_APP_BACKEND_BASE_URL;
export const AWS_URL = process.env.REACT_APP_AWS_URL;
export const MAX_FILE_SIZE = 500 * 1024; // 500kb
export const MAX_VIDEO_SIZE = 2000000; // 2000000 KB
export const MAX_IMAGE_SIZE = 2000000; // 2000000 KB

export const CONTENT_POSITION_OPTIONS = [
  { value: "left", label: "Left" },
  { value: "right", label: "Right" },
  { value: "center", label: "Center" },
];

// export const CONTENT_URLS_OPTIONS = [
//   { value: "/", label: "Home" },
//   { value: "/about", label: "About" },
//   { value: "/contact", label: "Contact" },
// ];

export const useContentUrls = () => {
  const [contentUrls, setContentUrls] = useState<{ value: string; label: string }[]>([]);

  useEffect(() => {
    const fetchButtonLinks = async () => {
      try {
        const buttonLinks = await ButtonLinkAPI(PATH_NAME.API_ADMIN_BUTTON_LINK);
        const items = buttonLinks.result?.items || [];
        const options = items.map((item: any) => ({
          value: item.url,
          label: item.name,
        }));
        setContentUrls(options);
      } catch (error) {
        console.error('Error fetching button links:', error);
      }
    };

    fetchButtonLinks();
  }, []);

  return contentUrls;
};

