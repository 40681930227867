import React from "react";
import SkuLayoutPage from "../SkuLayoutPage";
import EditSkuDetails from "./EditSkuDetails";
import NutritionFact from "./EditNutritionFact";
import HQSku from "../HQSku";

const SkuDetails: React.FC = () => {
  const tabs = [
    {
      label: "HQ Sku",
      content: <HQSku />,
    },
    {
      label: "Edit SKU Details",
      content: <EditSkuDetails />,
    },
    {
      label: "Nutrition Facts",
      content: <NutritionFact />,
    },
  ];

  const title = "SKU Details";
  const backLink = "/product/skus";

  return (
    <div>
      <SkuLayoutPage title={title} backLink={backLink} tabs={tabs} />
    </div>
  );
};

export default SkuDetails;
