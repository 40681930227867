import {
  Box,
  Button,
  FormControl,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import ImagePreview from "../Sku/ImagePreview";
import { ChangeEvent, useState } from "react";
import { IBadgeCreate } from "../../../../models/IBadge";
import { AWS_URL } from "../../../../configs/constants";
import { createBadgeApi } from "../../../../api/BadgeAPI";
import { useNavigate } from "react-router-dom";
import { enqueueSnackbar } from "notistack";

const defaultFormData: IBadgeCreate = {
  name: "",
  filename: "",
};

const AddBadge = () => {
  const [formData, setFormData] = useState<IBadgeCreate>(defaultFormData);

  const navigate = useNavigate();

  const handleImageUrlChange = (url: string) => {
    setFormData((prev) => ({ ...prev, filename: url }));
  };

  const handleNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFormData((prev) => ({ ...prev, name: e.target.value }));
  };

  const handleCancel = () => {
    navigate("/settings/badges");
  };

  const handleSubmit = async () => {
    try {
      await createBadgeApi(formData);
      navigate("/settings/badges");
      enqueueSnackbar("Badge created successfully!", {
        variant: "success",
      });
      setFormData(defaultFormData);
    } catch (error) {
      enqueueSnackbar("Failed to create badge.", { variant: "error" });
    }
  };

  return (
    <Box>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h6" gutterBottom>
          Create Badge
        </Typography>
      </Box>

      <Grid container spacing={2}>
        <Grid item container xs={12}>
          <Grid item xs={2} sx={{ placeContent: "center" }}>
            <Typography variant="subtitle2" fontWeight="bold">
              Badge Image
            </Typography>
            <Typography sx={{ fontSize: "12px", color: "red" }}>
              550px * 440px
            </Typography>
          </Grid>
          <Grid item xs={10} sx={{ placeContent: "center" }}>
            <FormControl fullWidth>
              <ImagePreview
                onImageUrlChange={handleImageUrlChange}
                value={
                  formData?.filename ? `${AWS_URL}/${formData.filename}` : ""
                }
              />
            </FormControl>
          </Grid>
        </Grid>

        <Grid item container xs={12}>
          <Grid item xs={2} sx={{ placeContent: "center" }}>
            <Typography variant="subtitle2" fontWeight="bold">
              Badge Name
            </Typography>
          </Grid>
          <Grid item xs={10} sx={{ placeContent: "center" }}>
            <FormControl fullWidth>
              <TextField
                label="Badge Name"
                name="name"
                value={formData.name}
                size="small"
                fullWidth
                onChange={handleNameChange}
              />
            </FormControl>
          </Grid>
        </Grid>

        <Stack
          direction="row"
          justifyContent="flex-end"
          gap={2}
          mt={4}
          sx={{ width: "100%" }}
        >
          <Button variant="contained" color="inherit" onClick={handleCancel}>
            Cancel
          </Button>
          <Button variant="contained" color="primary" onClick={handleSubmit}>
            Save
          </Button>
        </Stack>
      </Grid>
    </Box>
  );
};

export default AddBadge;
