import React, { useState } from "react";
import {
  Box,
  Button,
  Typography,
  IconButton,
  Stack,
  CircularProgress,
  Grid,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import TasteBudForm from "./TasteBudForm";
import { IAssetItems } from "../../../../models/IAssets";
import AddIcon from "@mui/icons-material/Add";
import { addAssetAPI } from "../../../../api/AssetAPI";

const generateId = () => Math.random().toString(36).substr(2, 9);

const initialTasteBudItems: IAssetItems[] = [
  {
    id: generateId(),
    asset: "",
    text: "",
    text_color: "",
    btn_text: "",
    btn_url: "",
    position: "",
  },
];

const TasteBudAdd: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [tasteBudItems, setTasteBudItems] =
    useState<IAssetItems[]>(initialTasteBudItems);

  const handleSubmit = async () => {
    if (tasteBudItems.length === 0) {
      enqueueSnackbar("Please provide at least one item.", {
        variant: "warning",
      });
      return;
    }

    try {
      setIsSubmitting(true);

      await addAssetAPI({ placement: "tastebud", items: tasteBudItems });
      enqueueSnackbar("Taste bud added successfully!", { variant: "success" });
      navigate(-1);
    } catch (error) {
      console.error("Error in handleSubmit:", error);
      enqueueSnackbar("Failed to add taste bud.", { variant: "error" });
    } finally {
      setIsSubmitting(false);
    }
  };

  const updateTasteBudItem = (item: IAssetItems, id: string) => {
    setTasteBudItems((prevItems) =>
      prevItems.map((prevItem) => (prevItem.id === id ? item : prevItem))
    );
  };

  const addTasteBudItem = () => {
    setTasteBudItems((prevItems) => [
      ...prevItems,
      {
        ...initialTasteBudItems[0],
        id: generateId(),
      },
    ]);
  };

  const removeTasteBudItem = (idToRemove: string) => {
    setTasteBudItems((prevItems) =>
      prevItems.filter((item) => item.id !== idToRemove)
    );
  };

  return (
    <Box sx={{ maxWidth: 800, margin: "0 auto" }}>
      <Stack direction="row" alignItems="center" spacing={2} mb={4}>
        <IconButton onClick={() => navigate(-1)}>
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="h5">Add New Taste Bud</Typography>
        <Typography sx={{fontSize:'12px', color:'red'}}>Size: 600px * 900px</Typography>
      </Stack>

      <Box sx={{ mb: 4 }}>
        <Typography variant="subtitle1" gutterBottom>
          Upload Items ({tasteBudItems.length}/3)
        </Typography>

        <Grid container spacing={1}>
          {tasteBudItems.map((item) => (
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              key={item.id}
              sx={{ width: "100%" }}
            >
              <TasteBudForm
                tasteBudItem={item}
                removeTasteBudItem={removeTasteBudItem}
                updateTasteBudItem={updateTasteBudItem}
                tasteBudId={item.id || ""}
              />
            </Grid>
          ))}
          {tasteBudItems.length < 3 && (
            <Grid item xs={12} sm={6} md={4}>
              <Box
                sx={{
                  width: "100%",
                  height: "100%",
                  border: "1px solid #ccc",
                  borderRadius: 1,
                  p: 2,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                  backgroundColor: "#f0f0f0",
                  "&:hover": {
                    backgroundColor: "#e0e0e0",
                  },
                }}
                onClick={addTasteBudItem}
              >
                <IconButton>
                  <AddIcon />
                </IconButton>
              </Box>
            </Grid>
          )}
        </Grid>
      </Box>

      <Stack direction="row" spacing={2} justifyContent="flex-end">
        <Button variant="outlined" onClick={() => navigate(-1)}>
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={handleSubmit}
          disabled={isSubmitting || tasteBudItems.length === 0}
        >
          {isSubmitting ? <CircularProgress size={24} /> : "Add Taste Bud"}
        </Button>
      </Stack>
    </Box>
  );
};

export default TasteBudAdd;
