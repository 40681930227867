import React, { useEffect, useState } from "react";
import {
  Button,
  Box,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Switch,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import useSWR, { useSWRConfig } from "swr";
import { PATH_NAME } from "../../../../configs/pathName";
import DataTable from "../../../common/DataTable/DataTable";
import { IAssetItems, IAssets } from "../../../../models/IAssets";
import { AWS_URL } from "../../../../configs/constants";
import { enqueueSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import {
  activateAssetAPI,
  deleteAssetAPI,
  getAssetAPI,
} from "../../../../api/AssetAPI";

const placement = "tastebud";

const TasteBudList = () => {
  const { mutate } = useSWRConfig();
  const navigate = useNavigate();
  const {
    data: banners,
    error,
    isLoading,
  } = useSWR(PATH_NAME.API_ADMIN_ASSETS, getAssetAPI);

  const [filteredBanners, setFilteredBanners] = useState<IAssets[]>([]);
  const [open, setOpen] = useState(false);
  const [selectedId, setSelectedId] = useState<number | null>(null);
  const [selectedBanner, setSelectedBanner] = useState<IAssets | undefined>(
    undefined
  );

  const columns = [
    { field: "id", headerName: "ID", flex: 0 },
    {
      field: "items",
      headerName: "Images",
      flex: 1,
      renderCell: (params: any) => (
        <Box sx={{ display: "flex", flexDirection: "row", gap: 1 }}>
          {Array.isArray(params.row.items) &&
            params.row.items.map((item: IAssetItems, index: number) => (
              <img
                key={index}
                src={`${AWS_URL}/${item.asset}`}
                alt={`TasteBud ${params.row.id} - ${index + 1}`}
                style={{
                  width: 50,
                  height: 50,
                  objectFit: "cover",
                  borderRadius: 4,
                }}
              />
            ))}
        </Box>
      ),
    },
    { field: "placement", headerName: "Placement", flex: 1 },
    {
      field: "active",
      headerName: "Active",
      flex: 0,
      renderCell: (params: any) => (
        <Switch
          checked={params.row.is_active}
          onChange={() => handleToggleActive(params.row.id)}
        />
      ),
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 0,
      renderCell: (params: any) => (
        <div>
          <IconButton
            aria-label="edit"
            size="small"
            color="primary"
            onClick={() => handleEdit(params.row.id)}
          >
            <EditIcon fontSize="small" />
          </IconButton>
          <IconButton
            aria-label="delete"
            size="small"
            color="primary"
            onClick={() => handleDeleteClick(params.row.id)}
          >
            <DeleteIcon fontSize="small" />
          </IconButton>
        </div>
      ),
    },
  ];

  const handleEdit = (id: number) => {
    const asset = filteredBanners.find((item) => item.id === id);
    setSelectedBanner(asset);
    // setIsEditModelOpen(true);
    navigate(`edit/${id}`);
  };

  const handleCreate = () => {
    // setIsAddModelOpen(true);
    navigate("add");
  };

  const handleDeleteClick = (id: number) => {
    setSelectedId(id);
    setOpen(true);
  };

  const handleDeleteConfirm = async () => {
    if (selectedId !== null) {
      try {
        await deleteAssetAPI(selectedId);
        await mutate(PATH_NAME.API_ADMIN_ASSETS);
        enqueueSnackbar("Taste bud deleted successfully", {
          variant: "success",
        });
      } catch (error) {
        console.error("Failed to delete banner:", error);
        enqueueSnackbar("Failed to delete banner", {
          variant: "error",
        });
      }
    }
    setOpen(false);
    setSelectedId(null);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedId(null);
    setSelectedBanner(undefined);
  };

  const handleToggleActive = async (id: number) => {
    await activateAssetAPI(id);
    await mutate(PATH_NAME.API_ADMIN_ASSETS);
  };

  useEffect(() => {
    if (banners) {
      const filtered = banners
        .filter((banner: IAssets) => banner.placement === placement)
        .sort((a: IAssets, b: IAssets) => (a.id ?? 0) - (b.id ?? 0));
      setFilteredBanners(filtered);
    }
  }, [banners]);

  if (error) {
    enqueueSnackbar("Failed to fetch taste buds", {
      variant: "error",
    });
  }

  return (
    <Box>
      <Box sx={{ display: "flex", justifyContent: "flex-end", mb: 2, gap: 1 }}>
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={handleCreate}
        >
          Add
        </Button>
      </Box>
      <DataTable
        rows={filteredBanners || []}
        columns={columns}
        getRowId={(row) => row.id}
        loading={isLoading}
      />
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{"Confirm Deletion"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this taste bud?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeleteConfirm} color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default TasteBudList;
