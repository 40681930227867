import { PATH_NAME } from "../configs/pathName";
import { handleError } from "../helpers/Fetchers/fetchers";
import { IBadgeCreate, IBadge } from "../models/IBadge";
import axiosInstance from "./axiosInstance";

export const createBadgeApi = async (badge: IBadgeCreate) => {
    try{
        const response = await axiosInstance.post(PATH_NAME.API_ADMIN_BADGE, badge);
        return response.data;
    }catch (error) {
        handleError(error, 'Error creating badge:');
        throw error;
      }
}

export const updateBadgeApi = async (id: string, badge: IBadge) => {
    try{
        const response = await axiosInstance.put(PATH_NAME.API_ADMIN_BADGE_UPDATE(id), badge);
        return response.data;
    }catch (error) {
        handleError(error, 'Error creating badge:');
        throw error;
      }
}

export const deleteBadgeAPI = async (id: string) => {
    try {
      const response = await axiosInstance.delete(PATH_NAME.API_ADMIN_BADGE_DELETE(id));
      return response.data;
    } catch (error) {
      handleError(error, 'Error deleting badge:');
      throw error;
    }
  };